import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { Desktop } from "./desktop";
import { Desktopv2 } from "./desktopv2";
import { ExperimentPage } from "./experiment";
import NewLayout from "./newlayout";
import ShareExp from "./share";
import StatisNewLayout from "./StatisNewLayout";

export const IndexExperimentPage = () => { 
  const [searchParams] = useSearchParams();

  const tagHtml = () => {
    if (searchParams.has("token")) {
      console.log("HAS TOKEN");
      return <ShareExp />;
    }
    if (window.innerWidth > 1024) {
      if (searchParams.has("token")) {
      console.log("HAS TOKEN");

        return <ShareExp />;
      } else {
      console.log("image__desktop");
      console.log(localStorage.getItem("_image"));
      
        return (
          <div className="image__desktop">
            {/* <Desktopv2 /> */}
            <NewLayout />
          </div>
        );
      }
    } else if (window.innerWidth < 1054 && window.innerWidth > 768) {
      //untuk ipad
      console.log("image__ipad");
      
      return (
        <div className="image__ipad">
          <Desktop />
        </div>
      );
    } else {
      console.log("ELSE StatisNewLayout");

      return <StatisNewLayout />;
    }
  };

  return <>{tagHtml()}</>;
};
