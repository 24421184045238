import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BackButton = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <span className="d-flex align-center">
        <Link className="d-flex align-center" to="/">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.6388 18L24.48 24.7184L21.0607 28.08L10.8 18L21.0607 7.92001L24.48 11.2816L17.6388 18Z"
              fill="white"
            />
          </svg>
          <h6 className="mb-0 fc-white fontw-8">{t("Kembali")}</h6>
        </Link>
      </span>
    </div>
  );
};
