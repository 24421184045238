import React, { useEffect } from "react";
import "../../assets/css/bootstrap.min.css";
import bgImage from "../../assets/images/spash-screen-taco-new.jpg";
import "./welcome.css";
import LogoImg from "../../assets/images/Logo_Floor_View_White.png";
import ButtonImg from "../../assets/images/Button_Image.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import utils from "../../utility/utils";
import { network } from "../../utility/network";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export default function Welcome() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  useEffect(() => {
    if (searchParams.has("q")) {
      getProductByName();
    } else {
      if (utils.fetchLocalStorage("product_by_name")) {
        localStorage.removeItem("product_by_name");
      }
    }
  }, []);

  const getProductByName = () => {
    network.get(
      "product-sku?q=" + searchParams.get("q"),
      {},
      (res) => {
        if (res.data && res.category) {
          utils.saveLocalStoreage("product_by_name", res.data.sku);
          let room = res.room;
          utils.saveLocalStoreage(
            "_image",
            process.env.REACT_APP_STORAGE_URL + room.image
          );
          utils.saveLocalStoreage(
            "_imageMobile",
            `${process.env.REACT_APP_STORAGE_URL}${
              room.image_mobile === null ? room.image : room.image_mobile
            }`
          );
          utils.saveLocalStoreage(
            "_originImage",
            process.env.REACT_APP_STORAGE_URL + room.image_full
          );
          utils.saveLocalStoreage(
            "_originImageMobile",
            `${process.env.REACT_APP_STORAGE_URL}${
              room.image_full_mobile === null
                ? room.image_full
                : room.image_full_mobile
            }`
          );
          utils.saveLocalStoreage("_idRoom", room.id);
          utils.saveLocalStoreage("_title", room.name);

          navigate(
            `/home?q=${res.data.id}&k=${res.data.category_id}&n=${res.category.name}`
          );
        } else {
          message.warning("Product not found");
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
        console.log("err ada error", err);
      },
      () => {}
    );
  };
  return (
    <>
      <div
        className="bg-cover"
        style={{
          backgroundImage: `url(${bgImage})`,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundPosition: "center",
        }}
      >
        <img
          src={LogoImg}
          className="img-fluid custom-logo-welcome"
          style={{
          }}
        />

        <Link to="/home">
          <img
            src={ButtonImg}
            alt="Button"
            className="button-image"
            style={{
              width: "180px",
              marginBottom: "20px",
            }}
          />
        </Link>
      </div>
    </>
  );
  
}

