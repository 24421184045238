import { Button, Modal } from "antd";
import heic2any from "heic2any";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { network } from "../../utility/network";
import utils from "../../utility/utils";
import { bas64Image } from "./image_static";
import { OverlayLoading } from "./overlay-loading";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";

export const UploadField = ({ callback, reload = false }) => {
  const { t } = useTranslation();

  const [isModalError, setIsModalError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(false);
  const [lp, setLP] = useState(false);
  const loadingRef1 = useRef();
  const loadingRef2 = useRef();
  const [tempIsUpload, setTempIsUpload] = useState(false);
  const [errorInput, setErrorInput] = useState({
    file: "",
  });
  const [loadingUp, setLoadingUp] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    loadingRef1.current.style.width = "0px";
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const getFile = ({ target: { files } }) => {
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Modal.info({
        title: "Format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",
        onOk() {},
      });
      return false;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = () => {
      // console.log("Base64 encode", reader.result); //base64encoded string
      // console.log("OKOK READER");
      // return;
      // utils.saveLocalStoreage("_originImage", reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };

    let re = /(?:\.([^.]+))?$/;
    let ext = re.exec(files[0].name)[1];
    let err = {
      file: "",
    };

    if (ext.includes("png") || ext.includes("jpg") || ext.includes("jpeg")) {
      // loading move
      setLP(true);
      loadingRef1.current.style.width = "90%";
      loadingRef2.current.style.width = "100%";

      if (window.innerWidth < 1054) {
        handleUploadImage(files[0]);
        console.log("MOBILE VIEW");
      } else {
        let lprogress = setTimeout(() => {
          clearInterval(lprogress);
          setLP(false);
          setFileSize(false);

          // Jika file lebih dari 35mb
          if (files[0].size > 36700160) {
            setFileSize(true);
            return;
          }

          setFile(files[0]);
          setFileName(files[0].name);
          handleUploadImage(files[0]);

          loadingRef1.current.style.width = "0px";
          loadingRef2.current.style.width = "0px";
        }, 3000);
      }
    } else {
      err["file"] = "Wrong file extension!";
    }
    setErrorInput(err);
  };

  const deleteFile = () => {
    setFile(null);
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "static.webp", { type: mime });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1000,
        1000
      );
    });

  const handleUploadImage = async (val) => {
    let formBody = new FormData();
    let staticFile = bas64Image;

    setLoadingUp(true);

    const MAX_FILE_SIZE = 4120; // 5MB

    let fileSizeKiloBytes = val.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      let valImage = await resizeFile(val);

      formBody.append("file1", valImage);
    } else {
      formBody.append("file1", val);
    }

    let staticc = dataURLtoFile(staticFile);

    let resizeImage = await resizeFile(val);

    formBody.append("uploadid", val);
    formBody.append("file2", staticc);

    let file_name_string = val.name;
    var file_name_array = file_name_string.split(".");
    var file_extension = file_name_array[file_name_array.length - 1];

    if (!val.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Modal.info({
        title: "Format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",
        onOk() {},
      });
      return false;
    }

    if (file_extension === "HEIC" || file_extension === "heic") {
      setIsModalError(true);

      heic2any({
        blob: val,
        toType: "image/jpg",
      })
        .then((res) => {})
        .catch((err) => {});

      Modal.info({
        title: "format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",

        onOk() {},
      });

      return;
    } else {
      setLoadingUp(true);

      network.post(
        "https://d5d7-95-111-202-146.ngrok-free.app/api/image",
        // "https://79e0-94-237-67-37.ap.ngrok.io/api/image", //flask
        // "https://taco.yokesen.com/api/image/upload", //flask
        // "https://94ea-94-237-67-37.ap.ngrok.io/api/image", //python without flask
        formBody,
        (res) => {
          utils.saveLocalStoreage("_originImage", res.img_origin);
          utils.saveLocalStoreage("_image", res.values);
          utils.saveLocalStoreage("_originImageMobile", res.img_origin);
          utils.saveLocalStoreage("_imageMobile", res.values);
          utils.saveLocalStoreage("is_upload", true);
          setTempIsUpload(true);

          window.location.href = "/experiment";
          // navigation("/experiment");

          if (reload) {
            callback();
          }
        },
        (err) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoadingUp(false);
        }
      );
    }
  };

  return (
    <>
      <div className="home__upload text-left big-margin">
        <h6 className="fc-white">{t("Upload foto ruangan")}</h6>
        <div>
          <div className="upload__wrapper border-sm">
            <div
              className={
                errorInput.file !== ""
                  ? "video-dotline-err border-sm"
                  : "video-dotline border-sm"
              }
            >
              {file !== null && !lp ? (
                <>
                  <span className="fontw-8 fonts-16 fc-white">{fileName}</span>
                  <Button onClick={deleteFile} type="primary" danger>
                    X
                  </Button>
                </>
              ) : (
                !lp && (
                  <>
                    <input
                      type="file"
                      className="video-upload"
                      onChange={getFile}
                      accept="image/*"
                    />
                    <span className="upl-img">
                      <svg
                        width="88"
                        height="88"
                        viewBox="0 0 88 88"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_91_49)">
                          <path
                            d="M14.4022 69.546H72.0108V44.3423H79.2119V73.1466C79.2119 74.1015 78.8326 75.0173 78.1573 75.6926C77.4821 76.3678 76.5663 76.7471 75.6114 76.7471H10.8016C9.8467 76.7471 8.93089 76.3678 8.25565 75.6926C7.58042 75.0173 7.20108 74.1015 7.20108 73.1466V44.3423H14.4022V69.546ZM50.4076 33.5406H68.4103L43.2065 58.7444L18.0027 33.5406H36.0054V11.9374H50.4076V33.5406Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_91_49">
                            <rect
                              width="86.413"
                              height="86.413"
                              fill="white"
                              transform="translate(0 1.13574)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span className="fc-white">
                      {t("Klik disini untuk pilih foto atau menyeret kesini")}
                    </span>
                    {fileSize === true ? (
                      <span style={{ color: "red" }}>
                        {t("Ukuran file tidak boleh lebih dari")} 10mb
                      </span>
                    ) : null}
                  </>
                )
              )}
              <div className="w-full relative" style={{ height: "10px" }}>
                <div
                  ref={loadingRef1}
                  className="progress__bar absolute backg-white border-sm h-10"
                >
                  <div
                    ref={loadingRef2}
                    className="progress__bar progress__move absolute backg-secondary border-sm h-10"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center w-full">
            <span className="input_notes">
              <span className="fc-danger">{errorInput.file}</span>
              <br />
              ** Max. size 10MB | Format (.png, .jpg, .jpeg)
            </span>
          </div>
        </div>
      </div>

      {!loadingUp || <OverlayLoading />}
    </>
  );
};
