import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./new.css";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";

import IC_download from "../../assets/icons/download.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import utils from "../../utility/utils";
import { network } from "../../utility/network";
import { Link, useSearchParams } from "react-router-dom";
import { NewUploadField } from "../../components/shared/newUpload";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../components/change-language/changeLanguage";

export default function MobileHome() {
  const [tempRoom, setTempRoom] = useState([]);
  const [tempTagItem, setTagItem] = useState([]);
  const [deskripsi, setDeskripsi] = useState({});
  const [loading_up, setLoading_up] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const [heightDesc, setHeightDesc] = useState(0);
  const [heightVh, setheightVh] = useState(0);
  const [styleHeight, setStyleHeight] = useState({});
  const refTag = useRef();
  const refVh = useRef();

  useEffect(() => {
    network.get(`home`, {}, (res) => {
      setTempRoom(res.data);
      setDeskripsi(res.deskirpsi);
    });
  }, []);

  useEffect(() => {
    console.log("refTag.current.offsetHeight ", refTag.current.offsetHeight);
    console.log("refVh.current.offsetHeight t", refVh.current.offsetHeight);
    setHeightDesc(refTag.current.clientHeight);
    setheightVh(refVh.current.offsetHeight);
    setStyleHeight({
      height: `${
        refVh.current.offsetHeight - refTag.current.offsetHeight - 125
      }px`,
    });
  }, []);

  useEffect(() => {
    console.log("heightVh", heightVh);
    console.log("heightDesc", heightDesc);
    console.log("styleHeight", styleHeight);
  }, [heightVh, heightDesc, styleHeight]);

  const responsive = {
    0: { items: 2 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <>
      <div
        ref={refVh}
        className="h-100vh padding__new_layout d-flex justify-content-between flex-column"
        style={
          window.innerWidth > 768
            ? { overflowX: "hidden", overflowY: "hidden" }
            : { overflowX: "hidden", overflowY: "hidden" }
        }
      >
        <div className="" ref={refTag}>
          <div
            className=" d-flex justify-content-between align-items-top "
            style={{ marginBottom: "1.5rem" }}
          >
                    <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
            <div className="d-flex mobile">
            <div className="pr-2" style={{ position: "relative", zIndex: 3 }}>
              <ChangeLanguage />  
              </div>
              <div
                className="d-md-none">
                <NewUploadField setLoading_up={setLoading_up} />
              </div>
            </div>
          </div>

          <div className="row justify-content-between no-gutters height_calc">
            <div className="col-md-4 deskripsi  new__font">
              <div className=" d-flex flex-column justify-content-between h-100 pb-md-0 ">
                <div className="">
                  {/* <h1
                    className="font-ghotam font-bold"
                    style={{ fontSize: "65px", marginBottom: 0 }}
                  >
                    {t("FLOOR VISUALIZER")}
                  </h1> */}
                  <p
                    className="my-md-0 font-ghotam-book deskripsi__new"
                    style={{ marginBottom: "6px", marginTop: "6px" }}
                  >
                    <div
                      style={{ marginBottom: "0opx" }}
                      dangerouslySetInnerHTML={{
                        __html: utils.languangeT(
                          deskripsi.description,
                          deskripsi.decription_en
                        ),
                      }}
                    />
                    {/* {deskripsi} */}
                  </p>
                </div>
                <div className="d-none d-md-block">
                  <NewUploadField setLoading_up={setLoading_up} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {tempRoom.length <= 0 ? (
          <div className="row">
            <div className="col-md-5 mb-3">
              <div
                className="loading col__category  w-100"
                style={{ height: "200px" }}
              ></div>
            </div>
            <div className="col-md-5">
              <div
                className="loading col__category  w-100"
                style={{ height: "200px" }}
              ></div>
            </div>
          </div>
        ) : (
          <div
            className="row justify-content-end no-gutters  "
            style={styleHeight}
          >
            {tempRoom.slice(0, 2).map((item, index) => {
              return (
                <div
                  className={`col-md-5 pl-md-3  col__category`}
                  key={index}
                  style={index === 0 ? { marginBottom: "1em" } : {}}
                >
                  <div className="h-100 ">
                    <Link
                      to={`/room/${item.id}${
                        searchParams.has("q") &&
                        searchParams.has("k") &&
                        searchParams.has("n")
                          ? "?q=" +
                            searchParams.get("q") +
                            "&k=" +
                            searchParams.get("k") +
                            "&n=" +
                            searchParams.get("n")
                          : ""
                      }`}
                      className="h-100 d-block"
                    >
                      <div
                        className="kategori__home__new px-5 "
                        style={{
                          height: "100%",
                          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.479167) 29.17%, rgba(0, 0, 0, 0.64) 100%), url(${
                            process.env.REACT_APP_STORAGE_URL
                          }${
                            item.image_mobile ? item.image_mobile : item.image
                          })`,
                        }}
                      >
                        <div className="d-none d-md-block"></div>
                        <div className=""></div>
                        <div className="type_kategori font-ghotam font-bold ">
                          {utils.languangeT(item.name, item.name_en)}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
