import "../../assets/css/bootstrap.min.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSpring } from "react-spring/three";
import React, { useState, useEffect, createRef, useCallback } from "react";
import { Canvas } from "react-three-fiber";
import utils from "../../utility/utils";
import { CanvasImage } from "../../components/experiment/canvas-image";
import { network } from "../../utility/network";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import watermark from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import Back from "../../components/back/back";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";

import { LeftSide } from "../../components/calculation/left-side";
import { RightSide } from "../../components/calculation/right-side";
import "./new.css";
import { NewLeft } from "../../components/calculation/new-left";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";

export const NewCalculationPage = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  const [floors, setFloors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [patterns, setPatterns] = useState([]);
  const [patternsAssets, setPatternsAssets] = useState([]);
  const [showed, setShowed] = useState("category");
  const [choosedFloor, setChoosedFloor] = useState(null);
  const [result, setResult] = useState(null);

  const [image, setImage] = useState("");
  const [originImage, setOriginImage] = useState("");
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const [styleWidth, setStyleWidth] = useState({});
  const [loadingUp, setLoadingUp] = useState(false);

  const [valueZoom, setValZoom] = useState(0);
  const [valueArah, setValArah] = useState(0);
  const [tempNameCategory, setTempNameCategory] = useState(null);
  const [isShareModalShow, setisShareModalShow] = useState(false);
  const [tokenShare, setTokenShare] = useState("");

  const navigate = useNavigate();

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [0.9, 0.9, 0.9],
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    if (!utils.fetchLocalStorage("isCalc")) {
      navigate("/home");
      return;
    }
  }, []);

  const [{ rotation }, setRotation] = useSpring(() => ({
    // positioning the floor [X, Y, Z]
    // -50, biar kemiringannya pas defaultnya
    rotation: [-50, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  var constAngle = 0;
  var constZoom = -50;

  useEffect(() => {
    console.log("DESKTOP VIEW");
  });

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  const getArah = (id) => {
    network.post(
      `detail-perspektif`,
      {
        room_id: utils.fetchLocalStorage("_idRoom"),
        produk_id: id,
      },
      (res) => {
        if (res.data) {
          handleMotif(res.data.posisi_motif, res.data.arah_motif);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleMotif = (posisi, arah) => {
    setValArah(arah);
    for (let index = 0; index < arah; index++) {
      if (index > 0) {
        handleChangeSlider(index);
      }
    }

    setValZoom(posisi + 1);
    for (let index = 88; index < posisi; index++) {
      if (index > 0) {
        handleArrangeFloor(index);
      }
    }
  };

  // slider arah
  const handleChangeSlider = (val) => {
    setValArah(val);
    // handle rotate image
    const angle = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [constZoom, 0, angle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constAngle = angle;
  };

  const handleArrangeFloor = (val) => {
    // handle zoom image
    const zoom = val + ((val * 10) / window.innerHeight) * 180; // 550 for ipad

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [zoom, 0, constAngle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constZoom = zoom;
  };

  const [imgWidth, setImageWidth] = useState(null);
  const [imgClient, setImageClient] = useState(null);
  const [imgHeightClient, setImageHeightClient] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);
  const [imageIsReady, setImageIsReady] = useState(false);

  const handleImageLoad = (event) => {
    // real code
    const imWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;

    if (imWidth < imgHeight) {
      setImageWidth(window.innerWidth);
      setImageHeight(window.innerHeight - 100);
    } else {
      setImageWidth(imWidth);
      setImageHeightClient(imgHeight);
    }

    setImageClient(event.target.clientWidth);
    setImageHeight(event.target.clientHeight);

    setImageIsReady(true);

    hendleStyleCanvas();
  };

  const ref = createRef();

  const hendleStyleCanvas = () => {
    if (550 <= window.innerWidth && window.innerWidth < 800) {
      setStyleWidth({ width: `100%`, height: "450px" });
    } else {
      if (window.innerWidth < 550) {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `100%`, height: "250px" });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      } else {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `${imgClient ?? 0}px` });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      }
    }
  };

  const styleCanvasFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
      ? imgWidth >= imgHeight
        ? { width: `100%`, height: "250px" }
        : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
            // border: "1px solid green",
          }
      : imgWidth >= imgHeight
      ? {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "1px solid yellow",
        }
      : {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "4px solid yellow",
        };

  useEffect(() => {
    // get the room that user has choose or upload
    let res = utils.fetchLocalStorage("_image");
    let resOrigin = utils.fetchLocalStorage("_originImage");

    getBase64FromUrl(res).then((ress) => {
      let base64Image = `${ress}`;
      setImage(base64Image);
    });

    getBase64FromUrl(resOrigin).then((ress) => {
      let base64Image = `${ress}`;
      setOriginImage(base64Image);
    });
  }, []);

  const { confirm } = Modal;
  const showPromiseConfirm = (message) => {
    confirm({
      title: t("Gagal!"),
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk() {},
      onCancel() {},
    });
  };

  const hendleBagikan = () => {
    if (!result) {
      showPromiseConfirm(t("Data tidak lengkap"));
      return;
    }

    let form = {
      jumlah_kebutuhan: Math.ceil(result.res).toString(),
      share_image_id: utils.fetchLocalStorage("_share_image_id"),
      panjang_ruangan: result.panjang,
      lebar_ruangan: result.lebar,
      waste: result.waste,
      besar_perbox: result.box,
    };

    // console.log(form);

    // return;

    let formShare = {
      image_url: utils.fetchLocalStorage("_image"),
      product_id: utils.fetchLocalStorage("_product_id"),
      asset_id: utils.fetchLocalStorage("asset_id"),
      url_layout: utils.fetchLocalStorage("select_product"),
      panjang_ruangan: result.panjang,
      lebar_ruangan: result.lebar,
      besar_perbox: result.box,
      waste: result.waste,
      jumlah_kebutuhan: Math.ceil(result.res).toString(),
      product_name: utils.fetchLocalStorage("product_name"),
      layout_name: utils.fetchLocalStorage("layout_name"),
      kategori: result.kategori,
    };

    if (utils.fetchLocalStorage("is_upload")) {
      formShare.is_upload = 1;
    }

    network.post(
      `create-share-link`,
      formShare,
      (res) => {
        setTokenShare(res.data.ref);
        setisShareModalShow(true);
        utils.saveLocalStoreage("_share_image_id", res.data.id);
      },
      (err) => {},
      () => {}
    );
  };

  // *********** return data
  return (
    <>
      {/* Modal share */}
      <Modal
        onCancel={() => setisShareModalShow(false)}
        footer={false}
        className="modal__experiment"
        onOk={() => setisShareModalShow(false)}
        visible={isShareModalShow}
      >
        <h4 className="fontw-6 text__black">
          {t("Bagikan hasil eksperimen!")}
        </h4>
        <div className="exp__copy__link fontw-6">
          {window.location.host + "/share?token=" + tokenShare}
        </div>

        <div className="mt-3 text__black">{t("Atau bagikan melalui :")}</div>
        <div className="d-flex mt-1">
          <FacebookShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <FacebookIcon className="exp__social__btn" />
          </FacebookShareButton>
          <WhatsappShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <WhatsappIcon className="exp__social__btn" />
          </WhatsappShareButton>
          <TwitterShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <TwitterIcon className="exp__social__btn" />
          </TwitterShareButton>
          <LineShareButton
            url={window.location.host + "/share?token=" + tokenShare}
          >
            <LineIcon className="exp__social__btn" />
          </LineShareButton>

          <Tooltip placement="top" title="Tersalin!" trigger="click">
            <div
              className="exp__social__btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.host}/share?token=${tokenShare}`
                );
              }}
            >
              <LinkOutlined />
            </div>
          </Tooltip>
        </div>
      </Modal>

      <div
        className={` ${
          window.innerWidth > 768
            ? "overflow-x-hidden"
            : "container-fluid position-relative"
        }`}
      >
        <div className="row justify-content-between no-gutters">
          <div
            className={`col-md-4 height_calc__layout ${
              window.innerWidth > 768 ? "layout__right padding__new_layout" : ""
            } `}
          >
            <div className="mb-md-5 mb-3">
              <div className="d-flex justify-content-between align-items-top">
                <Link to="/home">
                <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
                </Link>
                {/* share for mobile */}
                {result ? (
                  <div
                    className="share__mobile d-md-none"
                    onClick={hendleBagikan}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center btn_mb_share py-1 px-2">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.7744 11.0422C10.3376 11.4902 9.81279 11.7142 9.19993 11.7142C8.58707 11.7142 8.06262 11.4902 7.62656 11.0422C7.18976 10.595 6.97136 10.0571 6.97136 9.4285V9.25707L3.99064 7.71422C3.78636 7.88564 3.55905 8.02393 3.3087 8.12907C3.05762 8.23345 2.79279 8.28564 2.51422 8.28564C1.90136 8.28564 1.3769 8.06164 0.940844 7.61364C0.504044 7.16641 0.285645 6.6285 0.285645 5.99993C0.285645 5.37136 0.504044 4.83307 0.940844 4.38507C1.3769 3.93783 1.90136 3.71422 2.51422 3.71422C2.79279 3.71422 3.05762 3.76641 3.3087 3.87079C3.55905 3.97593 3.78636 4.11422 3.99064 4.28564L6.97136 2.74279V2.57136C6.97136 1.94279 7.18976 1.40488 7.62656 0.957644C8.06262 0.509644 8.58707 0.285645 9.19993 0.285645C9.81279 0.285645 10.3376 0.509644 10.7744 0.957644C11.2105 1.40488 11.4285 1.94279 11.4285 2.57136C11.4285 3.19993 11.2105 3.73783 10.7744 4.18507C10.3376 4.63307 9.81279 4.85707 9.19993 4.85707C8.92136 4.85707 8.6569 4.80488 8.40656 4.7005C8.15547 4.59536 7.92779 4.45707 7.7235 4.28564L4.74279 5.8285V6.17136L7.7235 7.71422C7.92779 7.54279 8.15547 7.4045 8.40656 7.29936C8.6569 7.19498 8.92136 7.14279 9.19993 7.14279C9.81279 7.14279 10.3376 7.36641 10.7744 7.81364C11.2105 8.26164 11.4285 8.79993 11.4285 9.4285C11.4285 10.0571 11.2105 10.595 10.7744 11.0422Z"
                            fill="white"
                          />
                        </svg>
                      <div className="" style={{ fontSize: "11px", fontWeight: 400 }}>
                        {t("Bagikan")}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className=" position-relative"
              style={window.innerWidth > 768 ? { paddingTop: "11px" } : null}
            >
              <div
                className="mb-2 mb-md-0"
                style={
                  window.innerWidth > 768
                    ? { position: "absolute", top: "-26px" }
                    : null
                }
              >
                <Back />
              </div>
              <NewLeft
                callback={(val) => {
                  setResult(val);
                }}
                callbackLoading={(val) => {
                  loading = false;
                }}
              />
            </div>
            {result !== null ? <RightSide data={result} /> : <></>}
          </div>

          <div
            className="col-md-8 text-white d-none d-md-block pl-5"
            style={{ overflow: "hidden" }}
          >
            <div
              className="h-100"
              id="layout__desktop__download"
              style={{ position: "fixed" }}
            >
              <div
                className="canvas__field mx-auto"
                style={
                  window.innerWidth > 768
                    ? { width: "100%", height: "100%" }
                    : styleCanvasFrame
                }
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={utils.fetchLocalStorage("select_product")}
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {/* {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )} */}
              </div>

              <img
                src={image}
                onLoad={handleImageLoad}
                alt="taco empty new-layout"
                className={"img-fluid image__rooom"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
