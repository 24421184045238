import { Button } from "antd";

export const ActionButton = ({
  className = "",
  callbackDownload,
  callbackUpload,
  callbackShare,
  shareable = false,
  canUpload = true,
}) => {
  return (
    <div className={className + " align-center"}>
      {!shareable || (
        <>
          <div className="mt-2 w-100">
            <Button
              onClick={callbackDownload}
              className={`font-bold  btn__download ${
                window.innerWidth > 768 ? "btn__download__desktop" : ""
              }`}
              style={{ fontSize: "16px" }}
            >
              Download
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
