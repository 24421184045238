import { Spin } from "antd";

export const OverlayLoading = () => {
  return (
    <div
      className="backg-white opac-30 fixed d-flex justify-center align-center"
      style={{
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        zIndex: 20,
      }}
    >
      <Spin />
    </div>
  );
};
