import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import utils from "../../utility/utils";

export default function RoomCategoryMobile({
  id,
  title,
  imgURL,
  imgUrlMobile,
  type,
  originImage,
  originImageMobile,
  keyIndex,
  title_en,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClickRoom = () => {
    utils.saveLocalStoreage("_image", imgURL);
    utils.saveLocalStoreage("_imageMobile", imgUrlMobile);
    utils.saveLocalStoreage("_originImage", originImage);
    utils.saveLocalStoreage("_originImageMobile", originImageMobile);
    utils.saveLocalStoreage("_idRoom", id);
    utils.saveLocalStoreage("_title", title);

    utils.saveLocalStoreage("is_upload", false);
    navigate(
      `/experiment${
        searchParams.has("q") && searchParams.has("k") && searchParams.has("n")
          ? "?q=" +
            searchParams.get("q") +
            "&k=" +
            searchParams.get("k") +
            "&n=" +
            searchParams.get("n")
          : ""
      }`
    );
  };
  return (
    <>
      <div
        className={`col-6 col__item ${
          keyIndex === 0 || keyIndex === 1 ? "mb-2" : ""
        } ${keyIndex % 2 === 0 ? "pr-2" : ""}`}
      >
        <div
          onClick={handleClickRoom}
          className="h-100 bg_item px-2 py-3 d-flex flex-column justify-content-end align-items-center text-center"
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.479167) 85.03%, rgba(0, 0, 0, 0.64) 100%), 
                  url(${originImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {utils.languangeT(title, title_en)}
        </div>
      </div>
    </>
  );
}
