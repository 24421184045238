import AliceCarousel from "react-alice-carousel";
import "./experiment.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { Card, Image, Modal, Select, Tooltip, Button } from "antd";
import NavButtonExperiment from "../../components/experiment/nav-button";
import { useSpring } from "react-spring/three";
import React, { useState, useEffect, createRef, useCallback } from "react";
import { Canvas } from "react-three-fiber";
import "./styles.css";
import utils from "../../utility/utils";
import { CanvasImage } from "../../components/experiment/canvas-image";
import { OverlayLoading } from "../../components/shared/overlay-loading";
import { skeletonItems } from "../../components/experiment/skeleton-items";
import { network } from "../../utility/network";
import square_taco from "../../assets/images/taco-square.webp";
import { Option } from "antd/lib/mentions";
import { toPng } from "html-to-image";
import { ActionButton } from "../../components/experiment/action-button";
import { UploadField } from "../../components/shared/upload_field";
import { Link, useSearchParams } from "react-router-dom";
import watermark from "../../assets/images/logo.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { LinkOutlined } from "@ant-design/icons";
import { ExperimentAction } from "../../components/experiment/experimen-action";
import { Helmet } from "react-helmet";
import detectZoom from "detect-zoom";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import DetailShare from "../../components/experiment/detail_share";
import "./mobile.css";
import IC_Cal from "../../assets/icons/calculate.png";

export const ExperimentPage = () => {
  const [floors, setFloors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [patterns, setPatterns] = useState([]);
  const [patternsAssets, setPatternsAssets] = useState([]);
  const [showed, setShowed] = useState("category");
  const [choosedFloor, setChoosedFloor] = useState(null);
  const [readyCalc, setReadyCalc] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [image, setImage] = useState("");
  const [originImage, setOriginImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingUp, setLoadingUp] = useState(false);

  const [searchParams] = useSearchParams();

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [0.9, 0.9, 0.9],
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    console.log("MOBILE VIEW");
  });

  const [{ rotation }, setRotation] = useSpring(() => ({
    // positioning the floor [X, Y, Z]
    // -50, biar kemiringannya pas defaultnya
    rotation: [-50, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  var constAngle = 0;
  var constZoom = -50;

  const handleChangeSlider = (val) => {
    // handle rotate image
    const angle = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [constZoom, 0, angle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constAngle = angle;
  };

  const handleArrangeFloor = (val) => {
    // handle zoom image
    const zoom = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [zoom, 0, constAngle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constZoom = zoom;
  };

  const [imgWidth, setImageWidth] = useState(null);
  const [imgClient, setImageClient] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);
  const handleImageLoad = (event) => {
    const imWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;

    setImageClient(event.target.clientWidth);

    if (imWidth < imgHeight) {
      setImageWidth(window.innerWidth);
      setImageHeight(window.innerHeight - 100);
    } else {
      setImageWidth(imWidth);
      setImageHeight(imgHeight);
    }
  };

  const ref = createRef();

  // handle to download image
  const handleDownload = () => {
    setLoadingUp(true);
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "TACO-VISUALIZER.png";
        link.href = dataUrl;
        link.click();
      })
      .then((res) => {
        setLoadingUp(false);
      })
      .catch((err) => {
        console.log(err, "Error code");
        setLoadingUp(false);
      });
  };

  const handleCaptureClick = useCallback(async () => {
    setLoadingUp(true);
    const pricingTableElmt = document.querySelector(
      ".experiment_layout__mobile"
    );
    if (!pricingTableElmt) return;

    const canvas = await html2canvas(pricingTableElmt);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "TACO IMAGE.png", "image/png");

    setLoadingUp(false);
  }, []);

  // screenshot the element
  // const getImage = () => takeScreenshot(ref.current).then(handleDownload);

  // choose floor to be set in CANVAS
  const handleChooseFloor = (floor) => {
    let imageFLoor = getBase64FromUrl(floor);
    handleLoading();
    setChoosedFloor(floor);
  };

  const fetchPattern = (id) => {
    network.get(
      `pattern-by-product/${id}`,
      {},
      (res) => {
        setPatterns(res.layouts);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const fetchPatternAssets = (id) => {
    network.get(
      `asset`,
      { product_id: id },
      (res) => {
        if (res.data.length > 0) {
          let imgURL = process.env.REACT_APP_STORAGE_URL + res.data[0].image;

          handleChooseFloor(imgURL);
          setPatternsAssets(res.data);
          setSelectedPattern(res.data[0].id);
          utils.saveLocalStoreage("select_product", imgURL);
          utils.saveLocalStoreage("asset_id", res.data[0].id);
          utils.saveLocalStoreage("_pattern_id", res.data[0].layout_id);
          utils.saveLocalStoreage("isCalc", true);
          setReadyCalc(true);
        } else {
          utils.messageError("Tidak ada asset pattern untuk product ini!");
          utils.saveLocalStoreage("isCalc", false);
          setReadyCalc(false);
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  // choose floor to be set in CANVAS
  const handleSelectProduct = (id, name) => {
    utils.saveLocalStoreage("_product_id", id);
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);

    fetchPattern(id);
    fetchPatternAssets(id);
    setSelectedProduct(id);
    utils.saveLocalStoreage("product_name", name);
  };

  useEffect(() => {
    if (searchParams.has("k") && searchParams.has("n")) {
      handleSelectCategory(searchParams.get("k"), searchParams.get("n"));
    }
  }, []);

  // select category and show the product
  const handleSelectCategory = (id) => {
    setLoading(true);
    network.get(
      `kategori/${id}`,
      {},
      (res) => {
        utils.saveLocalStoreage("_category_id", id);
        setProducts(res.data.product);
        setShowed("products");

        if (searchParams.has("q")) {
          handleSelectProduct(
            searchParams.get("q"),
            utils.fetchLocalStorage("product_by_name")
          );
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const styleCanvasFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
      ? imgWidth >= imgHeight
        ? { width: `100%`, height: "250px" }
        : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
            // border: "2px solid red",
          }
      : imgWidth >= imgHeight
      ? { width: `${imgClient ?? 0}px` }
      : {
          height: `${imgHeight ?? 0}px`,
          width: `${imgWidth ?? 0}px`,
          // border: "2px solid yellow",
        };

  const styleImageFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
      ? imgWidth >= imgHeight
        ? { width: `100%`, height: "250px" }
        : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          }
      : imgWidth >= imgHeight
      ? { width: `inherit` }
      : {
          height: `${imgHeight ?? 0}px`,
          width: `${imgWidth ?? 0}px`,
        };

  const tagImageMobile = () => {
    if (searchParams.has("token")) {
      return (
        <img
          preview={false}
          className={
            "target__image__experiment with__token " +
            (imgWidth > imgHeight ? "m-auto" : "")
          }
          src={image}
          alt="taco empty "
          onLoad={handleImageLoad}
          style={styleImageFrame}
        />
      );
    } else {
      return (
        <img
          preview={false}
          className={
            "target__image__experiment " +
            (imgWidth > imgHeight ? "m-auto" : "")
          }
          src={
            choosedFloor === null && patternsAssets.length === 0
              ? originImage
              : image
          }
          alt="taco empty "
          onLoad={handleImageLoad}
          style={styleImageFrame}
        />
      );
    }
  };

  const tagImage = () => {
    if (searchParams.has("token")) {
      if (styleCanvasFrame.width === "0px") {
        return (
          <img
            preview={false}
            className="target__image__experiment"
            src={image}
            alt="taco empty "
            onLoad={handleImageLoad}
            style={styleImageFrame}
          />
        );
      } else {
        return (
          <img
            preview={false}
            className="target__image__experiment image__mobile"
            src={image}
            alt="taco empty "
            onLoad={handleImageLoad}
            style={styleCanvasFrame}
          />
        );
      }
    } else {
      if (styleCanvasFrame.width === "0px") {
        return (
          <img
            preview={false}
            className="target__image__experiment"
            src={
              choosedFloor === null && patternsAssets.length === 0
                ? originImage
                : image
            }
            alt="taco empty "
            onLoad={handleImageLoad}
            style={styleImageFrame}
          />
        );
      } else {
        return (
          <img
            preview={false}
            className="target__image__experiment image__mobile"
            src={
              choosedFloor === null && patternsAssets.length === 0
                ? originImage
                : image
            }
            alt="taco empty "
            onLoad={handleImageLoad}
            style={styleCanvasFrame}
          />
        );
      }
    }
  };

  useEffect(() => {
    function handleKeyDown(e) {
      let charCode = String.fromCharCode(e.which).toLowerCase();

      if ((e.ctrlKey || e.metaKey) && e.code === "Equal") {
        document.body.style.zoom = "100%";
      }
    }

    document.body.style.zoom = "100%";

    var scale = "scale(1)";
    document.body.style.webkitTransform = scale; // Chrome, Opera, Safari
    document.body.style.msTransform = scale; // IE 9
    document.body.style.transform = scale; // General

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const hendleLanjutkanZoom = () => {
    setIsModalOpen(false);
  };

  const hendleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    // get the room that user has choose or upload
    let res = utils.fetchLocalStorage("_imageMobile");
    let resOrigin = utils.fetchLocalStorage("_originImageMobile");

    getBase64FromUrl(res).then((ress) => {
      let base64Image = `${ress}`;
      setImage(base64Image);
    });

    getBase64FromUrl(resOrigin).then((ress) => {
      let base64Image = `${ress}`;
      setOriginImage(base64Image);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    network.get(
      `list-kategori`,
      {},
      (res) => {
        setFloors(res.data);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );

    if (searchParams.has("token")) {
      network.get(
        `share`,
        { ref: searchParams.get("token") },
        (res) => {
          setChoosedFloor(res.data.url_layout);
          setImage(res.data.image);
        },
        (err, msg) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [searchParams]);

  const handleBackCarousel = () => {
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);
    if (showed === "bricks") {
      setShowed("products");
    } else if (showed === "products") {
      setShowed("category");
    }
  };

  const handleSelectPattern = (val) => {
    let res = patternsAssets.filter((item) => item.layout_id === val);

    if (res.length > 0) {
      let imgURL = process.env.REACT_APP_STORAGE_URL + res[0].image;
      utils.saveLocalStoreage("_pattern_id", val);
      handleChooseFloor(imgURL);
      setSelectedPattern(res[0].id);
    }
  };

  const LoadingSection = () => {
    return (
      <AliceCarousel
        responsive={{
          1600: {
            items: 9,
          },
          1440: {
            items: 8,
          },
          1280: {
            items: 8,
          },
          991: {
            items: 6,
          },
          768: {
            items: 5,
          },
          660: {
            items: 4,
          },
          425: {
            items: 4,
          },
          375: {
            items: 3,
          },
          200: {
            items: 3,
          },
        }}
        mouseTracking
        items={skeletonItems}
      ></AliceCarousel>
    );
  };

  const CategorySection = () => {
    return loading ? (
      <LoadingSection />
    ) : floors.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <AliceCarousel
        className="carousel__mobile"
        responsive={{
          1600: {
            items: 9,
          },
          1440: {
            items: 8,
          },
          1280: {
            items: 8,
          },
          991: {
            items: 6,
          },
          768: {
            items: 5,
          },
          660: {
            items: 4,
          },
          425: {
            items: 4,
          },
          375: {
            items: 3,
          },
          200: {
            items: 3,
          },
        }}
        mouseTracking
        items={floors.map((item) => {
          return (
            <Card
              onClick={() => {
                handleSelectCategory(item.id);
              }}
              key={item.id}
              className="cursor-pointer border-sm relative carousel__card text-center"
            >
              <img src={square_taco} className="floor__image" alt="" />
              <div className="absolute title__card">
                <p className="mb-0 fc-white">{item.name}</p>
              </div>
            </Card>
          );
        })}
      ></AliceCarousel>
    );
  };

  const ProductsSection = () => {
    return loading ? (
      <LoadingSection />
    ) : products.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <AliceCarousel
        className="carousel__mobile"
        responsive={{
          1600: {
            items: 9,
          },
          1440: {
            items: 8,
          },
          1280: {
            items: 8,
          },
          991: {
            items: 6,
          },
          768: {
            items: 5,
          },
          660: {
            items: 4,
          },
          425: {
            items: 4,
          },
          375: {
            items: 3,
          },
          200: {
            items: 3,
          },
        }}
        mouseTracking
        items={products.map((item) => {
          return (
            <Card
              onClick={() => {
                handleSelectProduct(item.id, item.name);
              }}
              key={item.id}
              className={`${
                item.id === selectedProduct ? "active" : ""
              } cursor-pointer border-sm relative carousel__card text-center`}
            >
              <img
                src={process.env.REACT_APP_STORAGE_URL + item.image}
                className="floor__image"
                alt=""
              />
              <div className="absolute title__card">
                <p className="mb-0 fc-white">{item.name}</p>
              </div>
            </Card>
          );
        })}
      ></AliceCarousel>
    );
  };

  const createShareLink = () => {
    let form = {
      image_url: utils.fetchLocalStorage("_imageMobile"),
      product_id: selectedProduct,
      asset_id: selectedPattern,
      url_layout:
        choosedFloor === null
          ? patternsAssets.length === 0
            ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
            : process.env.REACT_APP_STORAGE_URL + patternsAssets[0].image
          : choosedFloor,
    };

    if (utils.fetchLocalStorage("is_upload")) {
      form.is_upload = 1;
    }

    network.post(
      `create-share-link`,
      form,
      (res) => {
        setTokenShare(res.data.ref);
        triggerModalShare(res.data);
        utils.saveLocalStoreage("_share_image_id", res.data.id);
      },
      (err) => {},
      () => {}
    );
  };

  const triggerModalShare = () => {
    setisShareModalShow(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalShow, setisShareModalShow] = useState(false);
  const [tokenShare, setTokenShare] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const [loadingImage, setLoadingImage] = useState(false);

  const handleLoading = () => {
    let lprogress = setTimeout(() => {
      clearInterval(lprogress);
    }, 2000);
  };

  return (
    <div
      id="experiment "
      className=""
      style={{ paddingBottom: `calc(100vh - ${imgHeight - 83}px)` }}
    >
      <div id="experiment" className="main relative">
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <Modal
          footer={false}
          className="modal__experiment"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        >
          <UploadField
            reload={true}
            callback={() => {
              let res = utils.fetchLocalStorage("_image");
              let resImgMobile = utils.fetchLocalStorage("_imageMobile");
              let resOrigin = utils.fetchLocalStorage("_originImage");
              setImage(resImgMobile);
              setOriginImage(resOrigin);
              setIsModalVisible(false);
            }}
          />
        </Modal>

        <Modal
          onCancel={() => setisShareModalShow(false)}
          footer={false}
          className="modal__experiment"
          onOk={() => setisShareModalShow(false)}
          visible={isShareModalShow}
        >
          <h4 className="fontw-6 fc-white">Bagikan hasil eksperimen!</h4>
          <div className="exp__copy__link fontw-6">
            {window.location.href + "?token=" + tokenShare}
          </div>

          <div className="mt-3 fc-white">Atau bagikan melalui :</div>
          <div className="d-flex mt-1">
            <FacebookShareButton
              url={window.location.href + "?token=" + tokenShare}
            >
              <FacebookIcon className="exp__social__btn" />
            </FacebookShareButton>
            <WhatsappShareButton
              url={window.location.href + "?token=" + tokenShare}
            >
              <WhatsappIcon className="exp__social__btn" />
            </WhatsappShareButton>
            <TwitterShareButton
              url={window.location.href + "?token=" + tokenShare}
            >
              <TwitterIcon className="exp__social__btn" />
            </TwitterShareButton>
            <LineShareButton
              url={window.location.href + "?token=" + tokenShare}
            >
              <LineIcon className="exp__social__btn" />
            </LineShareButton>

            <Tooltip placement="top" title="Tersalin!" trigger="click">
              <div
                className="exp__social__btn"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.href}?token=${tokenShare}`
                  );
                }}
              >
                <LinkOutlined />
              </div>
            </Tooltip>
          </div>
        </Modal>

        <div
          ref={ref}
          className={
            "relative experiment_layout " +
            (searchParams.has("token") ? "share" : "")
          }
        >
          {!loading ? (
            <div className="div__for__download">
              <div
                className="canvas__field image__desktop"
                style={styleImageFrame}
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>

              {/* canvas mobile */}
              <div
                className={
                  "canvas__field image__mobile  " +
                  (imgWidth > imgHeight ? "m-auto" : "")
                }
                style={styleCanvasFrame}
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>
              {/* Tag Image */}
              <div className="download__boot">
                <div className="image__desktop">{tagImage()}</div>
                <div className="image__mobile">{tagImageMobile()}</div>
              </div>
            </div>
          ) : (
            <OverlayLoading />
          )}
        </div>
        <div
          ref={ref}
          className={
            "relative experiment_layout__mobile " +
            (searchParams.has("token") ? "share" : "")
          }
        >
          {!loading ? (
            <div className="div__for__download">
              <div
                className="canvas__field image__desktop"
                style={{ width: "65%", height: "auto" }}
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>

              {/* canvas mobile */}
              <div
                className={"canvas__field image__mobile m-auto"}
                style={styleCanvasFrame}
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>
              {/* Tag Image */}
              <div className="download__boot">
                <div className="image__desktop">{tagImage()}</div>
                <div className="image__mobile">
                  <img
                    preview={false}
                    className={"target__image__experiment m-auto"}
                    src={
                      choosedFloor === null && patternsAssets.length === 0
                        ? originImage
                        : image
                    }
                    alt="taco empty "
                    onLoad={handleImageLoad}
                    style={styleImageFrame}
                  />
                </div>
              </div>
            </div>
          ) : (
            <OverlayLoading />
          )}
        </div>

        {/* {searchParams.has("token") || <NavButtonExperiment />} */}

        <Modal
          visible={isModalOpen}
          onOk={hendleLanjutkanZoom}
          onCancel={hendleReload}
        >
          <h4>Memperbesar halaman layar akan mempengaruhi resolusi gambar</h4>
        </Modal>

        {!loadingUp || <OverlayLoading />}
      </div>
      <div className="fixed footer__experiment footer__mobile">
        {patterns.length === 0 || patternsAssets.length === 0 || (
          <div className="position-relative">
            <div className="d-flex justify-end px-3 mb-2 position-absolute">
              <Select
                className=" "
                defaultValue={patterns[0].layout_id}
                onChange={handleSelectPattern}
              >
                {patterns.map((item, idx) => (
                  <Option value={item.layout_id} key={idx}>
                    <div className="d-flex align-center">
                      <Image
                        preview={false}
                        src={
                          process.env.REACT_APP_STORAGE_URL + item.layout_image
                        }
                        width={window.innerWidth < 550 ? 30 : 50}
                        height={window.innerWidth < 550 ? 30 : 50}
                        alt="empty taco"
                      />
                      <p className="ml-2 mb-0">{item.layout_name}</p>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        {/* <ActionButton
          canUpload={!searchParams.has("token")}
          callbackDownload={handleCaptureClick}
          shareable={selectedPattern !== 0}
          callbackShare={createShareLink}
          callbackUpload={showModal}
          className="mb-2 d-sm-flex d-none"
        /> */}

        <div className="backg-primary pb-3 pt-2 relative">
          <div className="d-flex align-center justify-between px-sm-0 px-3 pb-1">
            <ExperimentAction
              showed={showed}
              showSlider={!searchParams.has("token") && selectedPattern === 0}
              callbackArange={handleArrangeFloor}
              callbackSpin={handleChangeSlider}
              callbackCarousel={handleBackCarousel}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
            />

            {/* <ActionButton
              canUpload={!searchParams.has("token")}
              callbackDownload={handleCaptureClick}
              shareable={selectedPattern !== 0}
              callbackShare={createShareLink}
              callbackUpload={showModal}
              className="d-flex d-sm-none"
            /> */}
          </div>

          <div className="">
            <DetailShare />
          </div>

          {searchParams.has("token") || (
            <>
              {showed === "category" ? (
                <CategorySection />
              ) : (
                <>
                  <ProductsSection />
                  {selectedProduct !== 0 ? (
                    <div className="header_cal px-1 mt-3 px-3">
                      {readyCalc ? (
                        <Link
                          to={"/calculation"}
                          className="calculate py-3 px-2 w-100"
                        >
                          <img src={IC_Cal} alt="" style={{ width: "30px" }} />
                          Kalkulasi Lantai 2
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
