import { Slider } from "antd";
import utils from "../../utility/utils";

export const ExperimentAction = ({
  showed,
  showSlider,
  callbackArange,
  callbackSpin,
  callbackCarousel,
  imgWidth,
  imgHeight,
}) => {
  const styleSlider =
    window.innerWidth < 800 ? { width: "120px" } : { width: "150px" };

  const minVal =
    window.innerWidth < 330
      ? 76
      : window.innerHeight < 800
      ? window.innerWidth < 550
        ? imgHeight > imgWidth
          ? 90
          : 80
        : 88
      : 100;

  const maxVal =
    window.innerWidth < 330
      ? 88
      : window.innerHeight < 800
      ? window.innerWidth < 550
        ? 100
        : 100
      : 115;

  const tagSlider = () => {
    if (utils.fetchLocalStorage("is_upload")) {
      if (!showSlider) {
        return (
          <div className="d-flex mb-2 d-none">
            <div className="ml-2 text-left" style={styleSlider}>
              <p className="fc-white mb-0 text-left">Atur Posisi Motif</p> 
              {/* BUKAN INI */}
              <Slider
                onChange={callbackArange}
                tooltipVisible={false}
                defaultValue={minVal}
                min={minVal}
                max={maxVal}
                disabled={false}
                trackStyle={{ backgroundColor: "black" }}
                handleStyle={{ backgroundColor: "white", border: "none" }}
              />
            </div>

            <div className="ml-2 text-left" style={styleSlider}>
              <p className="fc-white mb-0">Putar Arah Motif</p>
              <Slider
                onChange={callbackSpin}
                tooltipVisible={false}
                defaultValue={0}
                disabled={false}
                trackStyle={{ backgroundColor: "black" }}
                handleStyle={{ backgroundColor: "white", border: "none" }}
              />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="d-flex align-center px-md-3 pb-1">
      {showed === "category" || (
        <div
          className="back__carousel mb-2 fc-white backg-black border-sm py-1 px-3 cursor-pointer"
          style={{ zIndex: 1, top: "10px", right: "10px" }}
          onClick={callbackCarousel}
        >
          Kembali
        </div>
      )}

      {tagSlider()}
    </div>
  );
};
