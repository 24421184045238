import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./back.css";

export default function Back() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div
        className="d-flex align-items-center cursor-pointer back__component"
        onClick={handleBack}
        style={{ position: "relative" }}
      >
        <svg
          width={`${window.innerWidth > 768 ? 16 : 15}`}
          height={`${window.innerWidth > 768 ? 16 : 15}`}
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.07477 15.5L0 8L8.07477 0.5L10 2.28819L3.85047 8L10 13.7118L8.07477 15.5Z"
            fill="black"
            fillOpacity="0.4"
          />
        </svg>

        <span className="ml-2 cursor-pointer kembali font-bold">
          {t("KEMBALI")}
        </span>
      </div>
    </>
  );
}
