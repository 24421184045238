import { Slider } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const SliderZoom = ({
  showSlider,
  callbackArange,
  imgWidth,
  imgHeight,
  propMin,
}) => {
  const [tempMinVal, setMinVal] = useState();
  const [tempMaxVal, setMaxVal] = useState();
  const [tempPropMin, setPropMin] = useState();

  const styleSlider =
    window.innerWidth < 800 ? { width: "120px" } : { width: "100%" };

  useEffect(() => {
    handleVal();
  }, [propMin]);

  const handleVal = () => {
    if (propMin) {
      setPropMin(propMin);
      console.log("nas");
      console.log("temp prop min",propMin,tempPropMin);
      // setMinVal(propMin);
    } else {
      const minVal =
        window.innerWidth < 330
          ? 76
          : window.innerHeight < 800
          ? window.innerWidth < 550
            ? imgHeight > imgWidth
              ? 90
              : 80
            : 88
          : 100;
      setMinVal(minVal);
    }

    const minVal =
      window.innerWidth < 330
        ? 76
        : window.innerHeight < 800
        ? window.innerWidth < 550
          ? imgHeight > imgWidth
            ? 90
            : 80
          : 88
        : 100;
    setMinVal(minVal);

    const maxVal =
      window.innerWidth < 330
        ? 88
        : window.innerHeight < 800
        ? window.innerWidth < 550
          ? 100
          : 100
        : 115;

    setMaxVal(maxVal);

    setPropMin(propMin);
    console.log("TEMPPROPMIN",propMin,tempPropMin);
  };

  const tagSlider = () => {
    return (
      <div className="w-100 ">
        <div className="ml-2 text-left" style={styleSlider}>
          <p className="fc-white mb-0 text-left">Atur Posisi Motif</p>
          <Slider
            onChange={callbackArange}
            tooltipVisible={false}
            value={tempPropMin}
            min={tempMinVal}
            max={tempMaxVal}
            disabled={true}
            trackStyle={{ backgroundColor: "black" }}
            handleStyle={{
              backgroundColor: "black",
              border: "none",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-center ">{showSlider || tagSlider()}</div>
  );
};
