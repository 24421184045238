import "../../assets/css/bootstrap.min.css";
import "../../index.css";
import "./newlayout.css";
import AliceCarousel from "react-alice-carousel";
import "./experiment.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { Card, Col, Image, Modal, Row, Select, Tooltip } from "antd";
import NavButtonExperiment from "../../components/experiment/nav-button";
import NavButtonExperimentNew from "../../components/experiment/nav-button-new";
import { useSpring } from "react-spring/three";
import React, {
  useState,
  useEffect,
  createRef,
  useCallback,
  useRef,
} from "react";
import { Canvas } from "react-three-fiber";
import "./styles.css";
import utils from "../../utility/utils";
import { CanvasImage } from "../../components/experiment/canvas-image";
import { OverlayLoading } from "../../components/shared/overlay-loading";
import { skeletonItems } from "../../components/experiment/skeleton-items";
import { network } from "../../utility/network";
import square_taco from "../../assets/images/taco-square.webp";
import { Option } from "antd/lib/mentions";
import { toPng } from "html-to-image";
import { ActionButton } from "../../components/experiment/action-button"; 
import { UploadField } from "../../components/shared/upload_field";
import { Link, useSearchParams } from "react-router-dom";
import watermark from "../../assets/images/logo.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import { ExperimentAction } from "../../components/experiment/experimen-action";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import arrowLeft from "../../assets/icons/arrow-left-2.png";
import { ExperimentActionV2 } from "../../components/experiment/experimen-action-v2";
import { useTranslation } from "react-i18next";
import Back from "../../components/back/back";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";
import IC_Cal from "../../assets/icons/calculate.png";
import Alert from 'react-bootstrap/Alert';  

export default function StatisNewLayout() {
  const { t, i18n } = useTranslation();

  const [floors, setFloors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [patterns, setPatterns] = useState([]);
  const [patternsAssets, setPatternsAssets] = useState([]);
  const [showed, setShowed] = useState("category");
  const [choosedFloor, setChoosedFloor] = useState(null);

  const [image, setImage] = useState("");
  const [originImage, setOriginImage] = useState("");
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const [styleWidth, setStyleWidth] = useState({});
  const [loadingUp, setLoadingUp] = useState(false);

  const [imgWidth, setImageWidth] = useState(null);
  const [imgClient, setImageClient] = useState(null);
  const [imgHeightClient, setImageHeightClient] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);
  const [imageIsReady, setImageIsReady] = useState(false);

  const [valueZoom, setValZoom] = useState(0);
  const [valueArah, setValArah] = useState(0);
  const [tempNameCategory, setTempNameCategory] = useState(null);
  const [readyCalc, setReadyCalc] = useState(false);

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [0.9, 0.9, 0.9],
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  const [heightDesc, setHeightDesc] = useState(0);
  const [heightVh, setheightVh] = useState(0);
  const [styleHeightProduct, setStyleHeight] = useState({});
  const [styleHeightProductTwo, setStyleHeightTwo] = useState({});
  const [styleHeightCategory, setStyleHeightCategory] = useState({});
  const [styleHeightLayout, setStyleHeightLayout] = useState({});
  const refTag = useRef();
  const refVh = useRef();

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const [{ rotation }, setRotation] = useSpring(() => ({
    // positioning the floor [X, Y, Z]
    // -50, biar kemiringannya pas defaultnya
    rotation: [-50, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  var constAngle = 0;
  var constZoom = -50;

  useEffect(() => {
    console.log("MOBILE VIEW");
  });

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  const getArah = (id) => {
    network.post(
      `detail-perspektif`,
      {
        room_id: utils.fetchLocalStorage("_idRoom"),
        produk_id: id,
      },
      (res) => {
        if (res.data) {
          handleMotif(res.data.posisi_motif, res.data.arah_motif);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleMotif = (posisi, arah) => {
    setValArah(arah);
    for (let index = 0; index < arah; index++) {
      if (index > 0) {
        handleChangeSlider(index);
      }
    }

    setValZoom(posisi + 1);
    for (let index = 88; index < posisi; index++) {
      if (index > 0) {
        handleArrangeFloor(index);
      }
    }
  };

  // slider arah
  const handleChangeSlider = (val) => {
    setValArah(val);
    // handle rotate image
    const angle = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [constZoom, 0, angle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constAngle = angle;
  };

  const handleArrangeFloor = (val) => {
    // handle zoom image
    const zoom = val + ((val * 10) / window.innerHeight) * 280; // 550 for ipad

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [zoom, 0, constAngle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constZoom = zoom;
  };

  const handleImageLoad = (event) => {
    // real code
    const imWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;

    if (imWidth < imgHeight) {
      setImageWidth(window.innerWidth);
      setImageHeight(window.innerHeight - 100);
    } else {
      setImageWidth(imWidth);
      setImageHeightClient(imgHeight);
    }

    setImageClient(event.target.clientWidth);
    setImageHeight(event.target.clientHeight);

    // if (imageIsReady) {
      
    // } else {
    //   setStyleHeight({
    //     height: `${
    //       refVh.current.offsetHeight - refTag.current.offsetHeight - 20
    //     }px`,
    //   });
    // }

    setStyleHeight({
      height: `${
        refVh.current.offsetHeight - refTag.current.offsetHeight - 100
      }px`,
    });
    setStyleHeightCategory({
      height: `${
        refVh.current.offsetHeight - refTag.current.offsetHeight - 50
      }px`,
    });
    setStyleHeightLayout({
      height: `${
        refVh.current.offsetHeight - refTag.current.offsetHeight - 6
      }px`,
    });
    hendleStyleCanvas();
    setImageIsReady(true);
  };

  useEffect(() => {
    console.log("imgClient in image Load", imgClient);
    console.log("imgClient in image Load=====", imgClient);
  }, [imgClient]);

  const ref = createRef();

 // handle to download image
 const handleDownload = () => {
  setLoadingUp(true);
  toPng(ref.current)
    .then((dataUrl) => {
      const link = document.createElement("a");
      // Retrieve the selected product's name from the local storage
      const selectedProductName = utils.getLocalStoreage("product_name") || "TACO GAK";
      const prefix = "TACO Flooring "; 
      link.download = `${prefix}${selectedProductName}.png`;

      link.href = dataUrl;
      link.click();
    })
      .then((res) => {
        setLoadingUp(false);
      })
      .catch((err) => {
        console.log(err, "Error code");
        setLoadingUp(false);
      });
  };

  const handleCaptureClick = useCallback(async () => {
    setLoadingUp(true);
    const pricingTableElmt = document.querySelector(
      "#layout__desktop__download"
    );
    if (!pricingTableElmt) return;

    const canvas = await html2canvas(pricingTableElmt);

    const dataURL = canvas.toDataURL("image/png");

    // Retrieve the selected product's name from the local storage
    const selectedProductName = utils.getLocalStoreage("product_name") || "TACO GAK";
    const prefix = "TACO Flooring";

    // Set the desired file name
    const fileName = `${prefix}${selectedProductName}.png`;


    downloadjs(dataURL, fileName, "image/png");

    setLoadingUp(false);
  }, []);

  // screenshot the element
  // const getImage = () => takeScreenshot(ref.current).then(handleDownload);

  // choose floor to be set in CANVAS
  const handleChooseFloor = (floor) => {
    let imageFLoor = getBase64FromUrl(floor);
    handleLoading();
    setChoosedFloor(floor);
  };

  const fetchPattern = (id) => {
    network.get(
      `pattern-by-product/${id}`,
      {},
      (res) => {
        setPatterns(res.layouts);
        utils.saveLocalStoreage("layout_name", res.layouts[0].layout_name);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const fetchPatternAssets = (id) => {
    network.get(
      `asset`,
      { product_id: id },
      (res) => {
        if (res.data.length > 0) {
          let imgURL = process.env.REACT_APP_STORAGE_URL + res.data[0].image;

          handleChooseFloor(imgURL);
          setPatternsAssets(res.data);
          setSelectedPattern(res.data[0].id);
          utils.saveLocalStoreage("select_product", imgURL);
          utils.saveLocalStoreage("asset_id", res.data[0].id);
          utils.saveLocalStoreage("_pattern_id", res.data[0].layout_id);
          utils.saveLocalStoreage("isCalc", true);
          setReadyCalc(true);
        } else {
          setReadyCalc(false);
          utils.saveLocalStoreage("isCalc", false);
          utils.messageError("Tidak ada asset pattern untuk product ini!");
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  // choose floor to be set in CANVAS
  const handleSelectProduct = (id, name) => {
    utils.saveLocalStoreage("_product_id", id);
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);

    fetchPattern(id);
    fetchPatternAssets(id);
    setSelectedProduct(id);

    utils.saveLocalStoreage("product_name", name);

    getArah(id);
  };

  useEffect(() => {
    if (searchParams.has("k") && searchParams.has("n")) {
      handleSelectCategory(searchParams.get("k"), searchParams.get("n"));
    }
  }, []);

  // select category and show the product
  const handleSelectCategory = (id, name) => {
    console.log("name", name);
    setTempNameCategory(name);
    setLoading(true);
    network.get(
      `kategori/${id}`,
      {},
      (res) => {
        utils.saveLocalStoreage("_category_id", id);
        setProducts(res.data.product);
        setShowed("products");

        if (searchParams.has("q")) {
          handleSelectProduct(
            parseInt(searchParams.get("q")),
            utils.fetchLocalStorage("product_by_name")
          );
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const hendleStyleCanvas = () => {
    if (550 <= window.innerWidth && window.innerWidth < 800) {
      setStyleWidth({ width: `100%`, height: "450px" });
    } else {
      if (window.innerWidth < 550) {
        if (imgWidth >= imgHeight) {
          setStyleWidth({
            width: `100%`,
            height: "250px",
          });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      } else {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `${imgClient ?? 0}px` });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      }
    }
  };

  const styleCanvasFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
      ? imgWidth >= imgHeight
        ? { width: `100%`, height: imgHeight }
        : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
            // border: "1px solid green",
          }
      : imgWidth >= imgHeight
      ? {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "1px solid yellow",
        }
      : {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "4px solid yellow",
        };

  useEffect(() => {
    // get the room that user has choose or upload
    let res = utils.fetchLocalStorage("_image");
    let resOrigin = utils.fetchLocalStorage("_originImage");

    getBase64FromUrl(res).then((ress) => {
      let base64Image = `${ress}`;
      setImage(base64Image);
    });

    getBase64FromUrl(resOrigin).then((ress) => {
      let base64Image = `${ress}`;
      setOriginImage(base64Image);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    network.get(
      `list-kategori`,
      {},
      (res) => {
        setFloors(res.data);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );

    if (searchParams.has("token")) {
      network.get(
        `share`,
        { ref: searchParams.get("token") },
        (res) => {
          setChoosedFloor(res.data.url_layout);
          setImage(res.data.image);
        },
        (err, msg) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [searchParams]);

  const handleBackCarousel = () => {
    setImageIsReady(false);
    console.log("BACK HANDLE");
    setSelectedPattern(0);
    // setPatterns([]);
    // setPatternsAssets([]);
    if (showed === "bricks") {
      setShowed("products");
    } else if (showed === "products") {
      setShowed("category");
    }
  };

  const handleSelectPattern = (val) => {
    let res = patternsAssets.filter((item) => item.layout_id === val);

    if (res.length > 0) {
      let imgURL = process.env.REACT_APP_STORAGE_URL + res[0].image;
      utils.saveLocalStoreage("_pattern_id", val);
      utils.saveLocalStoreage("select_product", imgURL);

      let pattern = patterns.filter((item) => item.layout_id === val);
      if (pattern.length > 0) {
        utils.saveLocalStoreage("layout_name", pattern[0].layout_name);
      }

      handleChooseFloor(imgURL);
      setSelectedPattern(res[0].id);
    }
  };

  const LoadingSection = () => {
    return (
      <AliceCarousel
        responsive={{
          1600: {
            items: 9,
          },
          1440: {
            items: 8,
          },
          1280: {
            items: 8,
          },
          991: {
            items: 6,
          },
          768: {
            items: 5,
          },
          660: {
            items: 4,
          },
          425: {
            items: 4,
          },
          375: {
            items: 3,
          },
          200: {
            items: 3,
          },
        }}
        mouseTracking
        items={skeletonItems}
      ></AliceCarousel>
    );
  };

  const CategorySection = () => {
    return loading ? (
      <></>
    ) : floors.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <>
        <div className="mb-2 text-left fw-bold pilih__Kategori font-ghotam font-bold">
          {" "}
          {t("Kategori")}
        </div>
        <div className="category__mobile" style={styleHeightCategory}>
          {floors.map((item, i) => {
            return (
              <div className=" mb-2" key={i}>
                <div
                  className="card card__category position-relative d-flex align-items-center justify-content-center no-border-radius"
                  onClick={() => {
                    handleSelectCategory(item.id, item.name);
                  }}
                >
                  {/* <img
                    src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                    alt=""
                    className="img-fluid"
                  /> */}
                  <div className="card__footer text-center text-white w-100 text-left  position-absolute">
                    {item.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const ProductsSection = () => {
    return loading ? (
      // <LoadingSection />
      <></>
    ) : products.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <>
        <div className=" text-left fw-bold pilih__Kategori">
          <div className="row no-gutters align-items-top justify-content-between">
            {patterns.length === 0 || patternsAssets.length === 0 || (
              <>
                <div className="col-6 pr-1 py-1">
                  <div
                    className="btn__download btn__download__mobile btn no-border-radius"
                    onClick={handleDownload}
                  >
                    {t("Unduh Gambar")}
                  </div>
                </div>
                <div className="col-6 pl-1 py-1">
                  <Select
                    className="w-100 pattern__mobile select_experiment"
                    defaultValue={patterns[0].layout_id}
                    onChange={handleSelectPattern}
                    showArrow={false}
                    dropdownClassName="no-border-radius" // Add the custom class name here
                  >
                    {patterns.map((item, idx) => (
                      <Option value={item.layout_id} key={idx}>
                        <div className="row no-gutters">
                          <div className="col-5">
                            <Image
                              preview={false}
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                item.layout_image
                              }
                              width={"100%"}
                              height={66}
                              alt="empty taco pembuktian"
                            />
                          </div>
                          <div className="col-7">
                            <div className="bg__dark d-flex justify-content-center align-items-center">
                              <p
                                className="mb-0 text-center text-white"
                                style={{ fontSize: "13px" }}
                              >
                                {item.layout_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row__product__mobile" style={styleHeightProduct}>
          <div className="row no-gutters ">
            {products.map((item, i) => {
              return (
                <div
                  className={`col-6 py-1 ${i % 2 === 0 ? "pr-1" : "pl-1"}`}
                  key={i}
                >
                  <div
                    className={`card card__product  position-relative d-flex align-items-center justify-content-center no-border-radius ${
                      item.id === selectedProduct ? "active" : ""
                    }`}
                    onClick={() => {
                      handleSelectProduct(item.id, item.sku);
                    }}
                  >
                    <img
                      src={process.env.REACT_APP_STORAGE_URL + item.image}
                      className="img-fluid img__product"
                      alt=""
                    />
                    <div className="card__footer text-center font-bold text-white w-100 text-left  position-absolute">
                      {item.sku}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-2">
   <Alert variant="warning" style={{ fontSize: '10px', width: '100%', paddingLeft: '12px', paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
  {t("⚠️Preview yang ditampilkan bersifat indikatif dan tidak merupakan representasi yang akurat dari warna dan tekstur produk sebenarnya")}
</Alert>
 
  </div>
        </div>
      </>
    );
  };

  const createShareLink = () => {
    let form = {
      image_url: utils.fetchLocalStorage("_image"),
      product_id: selectedProduct,
      asset_id: selectedPattern,
      url_layout:
        choosedFloor === null
          ? patternsAssets.length === 0
            ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
            : process.env.REACT_APP_STORAGE_URL + patternsAssets[0].image
          : choosedFloor,
    };

    if (utils.fetchLocalStorage("is_upload")) {
      form.is_upload = 1;
    }

    network.post(
      `create-share-link`,
      form,
      (res) => {
        setTokenShare(res.data.ref);
        triggerModalShare(res.data);
        utils.saveLocalStoreage("_share_image_id", res.data.id);
      },
      (err) => {},
      () => {}
    );
  };

  const triggerModalShare = () => {
    setisShareModalShow(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalShow, setisShareModalShow] = useState(false);
  const [tokenShare, setTokenShare] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const [loadingImage, setLoadingImage] = useState(false);

  const handleLoading = () => {
    let lprogress = setTimeout(() => {
      clearInterval(lprogress);
    }, 2000);
  };

  // *********** return data
  return (
    <div
      ref={refVh}
      className="image__mobile d-flex flex-column justify-content-between"
    >
      <Modal
        footer={false}
        className="modal__experiment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <UploadField
          reload={true}
          callback={() => {
            let res = utils.fetchLocalStorage("_image");
            let resOrigin = utils.fetchLocalStorage("_originImage");
            setImage(res);
            setOriginImage(resOrigin);
            setIsModalVisible(false);
          }}
        />
      </Modal>

      {/* Modal share */}
      <Modal
        onCancel={() => setisShareModalShow(false)}
        footer={false}
        className="modal__experiment"
        onOk={() => setisShareModalShow(false)}
        visible={isShareModalShow}
      >
        <h4 className="fontw-6 fc-white">{t("Bagikan hasil eksperimen!")}</h4>
        <div className="exp__copy__link fontw-6">
          {window.location.href + "?token=" + tokenShare}
        </div>

        <div className="mt-3 fc-white">{t("Atau bagikan melalui :")}</div>
        <div className="d-flex mt-1">
          <FacebookShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <FacebookIcon className="exp__social__btn" />
          </FacebookShareButton>
          <WhatsappShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <WhatsappIcon className="exp__social__btn" />
          </WhatsappShareButton>
          <TwitterShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <TwitterIcon className="exp__social__btn" />
          </TwitterShareButton>
          <LineShareButton url={window.location.href + "?token=" + tokenShare}>
            <LineIcon className="exp__social__btn" />
          </LineShareButton>

          <Tooltip placement="top" title="Tersalin!" trigger="click">
            <div
              className="exp__social__btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.href}?token=${tokenShare}`
                );
              }}
            >
              <LinkOutlined />
            </div>
          </Tooltip>
        </div>
      </Modal>

      <div ref={refTag} className="overflow-x-hidden">
        <div className="row">
          <div className="col-md-9 text-white">
            {/* navbar */}
            <div className="container-fluid">
              <div className="mb-5 d-flex justify-content-between align-items-top ">
              <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
                {selectedProduct !== 0 ? (
                  <div className="header_cal__mobile ">
                    {readyCalc ? (
                      <Link
                        to={"/calculation"}
                        className="calculate__mobile p-1 px-2 w-100"
                        style={{ fontSize: "11px" }}
                      >
                        <img
                          src={IC_Cal}
                          alt=""
                          style={{ width: "auto", height: "14px" }}
                        />
                        {t("Kalkulasi")}
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* {showed !== "category" ? (
                <>
                  <div className="mb-2 mb-md-3 mt-2">
                    <div
                      className="d-flex align-items-center "
                      onClick={handleBackCarousel}
                      style={{
                        position: "absolute",
                        top: "-30px",
                        paddingLeft: "1em ",
                      }}
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer"
                      >
                        <path
                          d="M8.07477 15.5L0 8L8.07477 0.5L10 2.28819L3.85047 8L10 13.7118L8.07477 15.5Z"
                          fill="black"
                          fillOpacity="0.4"
                        />
                      </svg>

                      <span className="ml-2 cursor-pointer kembali kembali__mobile font-bold">
                        {t("KEMBALI")}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <></>
                <div className="mb-2 mb-md-3 mt-2">
                  <Back />
                </div>
              )} */}
            </div>
            {/* endnavbar */}

            <div
              className="position-relative pt-2"
              id="layout__desktop__download"
              ref={ref}
            >
              {showed === "category" ? (
                <div
                  className="mb-2 mb-md-0 "
                  style={{
                    position: "absolute",
                    top: "calc(-3rem - -1.5rem)",
                    paddingLeft: "1em ",
                  }}
                >
                  <Back />
                </div>
              ) : (
                <>
                  <div
                    className="d-flex align-items-center mb-2 mb-md-0 "
                    onClick={handleBackCarousel}
                    style={{
                      position: "absolute",
                      top: "calc(-3rem - -1.5rem)",
                      paddingLeft: "1em ",
                    }}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="cursor-pointer"
                    >
                      <path
                        d="M8.07477 15.5L0 8L8.07477 0.5L10 2.28819L3.85047 8L10 13.7118L8.07477 15.5Z"
                        fill="black"
                        fillOpacity="0.4"
                      />
                    </svg>

                    <span className="ml-2 cursor-pointer kembali kembali__mobile font-bold">
                      {t("KEMBALI")}
                    </span>
                  </div>
                </>
              )}
              <div className="canvas__field mx-auto" style={styleCanvasFrame}>
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image d-none"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>

              <img
                src={
                  choosedFloor === null && patternsAssets.length === 0
                    ? originImage
                    : image
                }
                onLoad={handleImageLoad}
                alt="taco empty new-layout"
                className={"img-fluid image__rooom"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="padding__new_layout__mobile" style={styleHeightLayout}>
        <div className="row">
          <div className="col-md-3 ">
            {!searchParams.has("token") && selectedPattern !== 0 ? (
              <></>
            ) : (
              <></>
            )}
            <div className="">
              {searchParams.has("token") || (
                <>
                  {showed === "category" ? (
                    <CategorySection />
                  ) : (
                    <>
                      <ProductsSection />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
