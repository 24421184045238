import { Card } from "antd";

export const skeletonItems = [
  <Card
    key={1}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
  <Card
    key={2}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
  <Card
    key={3}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
  <Card
    key={4}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
  <Card
    key={5}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
  <Card
    key={6}
    className="skeleton_loading cursor-pointer border-sm relative carousel__card"
  >
    <div className="absolute title__card">
      <p className="mb-0 fc-white skeleton_loading"></p>
    </div>
  </Card>,
];
