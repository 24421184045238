import { Button, Modal } from "antd";
import heic2any from "heic2any";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { network } from "../../utility/network";
import utils from "../../utility/utils";
import { bas64Image } from "./image_static";
import { OverlayLoading } from "./overlay-loading";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import IC_download from "../../assets/icons/download.png";
import "./upload.css";

export const NewUploadField = ({ callback, reload = false, setLoading_up }) => {
  const { t } = useTranslation();
  const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] = useState(false);

  const [isModalError, setIsModalError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(false);
  const [lp, setLP] = useState(false);
  const loadingRef1 = useRef();
  const loadingRef2 = useRef();
  const [tempIsUpload, setTempIsUpload] = useState(false);
  const [errorInput, setErrorInput] = useState({
    file: "",
  });
  const [loadingUp, setLoadingUp] = useState(false);

  const handleFileClick = () => {
    setIsDisclaimerModalVisible(true); // Show the disclaimer modal
  };
  // Triggered when the disclaimer is accepted
  const onDisclaimerAccept = () => {
    setIsDisclaimerModalVisible(false); // Close the modal
    document.getElementById("hidden-file-input").click(); // Trigger the file input
  };  

  const navigation = useNavigate();

  useEffect(() => {
    loadingRef1.current.style.width = "0px";
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const getFile = ({ target: { files } }) => {
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Modal.info({
        title: "Format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",
        onOk() {},
      });
      return false;
    }

    
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = () => {
      // console.log("Base64 encode", reader.result); //base64encoded string
      // console.log("OKOK READER");
      // return;
      // utils.saveLocalStoreage("_originImage", reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };

    let re = /(?:\.([^.]+))?$/;
    let ext = re.exec(files[0].name)[1];
    let err = {
      file: "",
    };

    if (ext.includes("png") || ext.includes("jpg") || ext.includes("jpeg")) {
      // loading move
      setLP(true);
      loadingRef1.current.style.width = "90%";
      loadingRef2.current.style.width = "100%";

      if (window.innerWidth < 1054) {
        handleUploadImage(files[0]);
        console.log("MOBILE VIEW");
      } else {
        let lprogress = setTimeout(() => {
          clearInterval(lprogress);
          setLP(false);
          setFileSize(false);

          // Jika file lebih dari 35mb
          if (files[0].size > 36700160) {
            setFileSize(true);
            return;
          }

          setFile(files[0]);
          setFileName(files[0].name);
          handleUploadImage(files[0]);

          loadingRef1.current.style.width = "0px";
          loadingRef2.current.style.width = "0px";
        }, 3000);
      }
    } else {
      err["file"] = "Wrong file extension!";
    }
    setErrorInput(err);

    setIsDisclaimerModalVisible(true);

    setTimeout(() => {
      setIsDisclaimerModalVisible(false);
    }, 4000);
  };

  const deleteFile = () => {
    setFile(null);
    utils.saveLocalStoreage("loading_upload", false);
  };

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "static.webp", { type: mime });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1000,
        1000
      );
    });

  const handleUploadImage = async (val) => {
    let formBody = new FormData();
    let staticFile = bas64Image;

    setLoadingUp(true);
    setLoading_up(true);
    // return;
    const MAX_FILE_SIZE = 4120; // 5MB

    let fileSizeKiloBytes = val.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      let valImage = await resizeFile(val);

      formBody.append("file1", valImage);
    } else {
      formBody.append("file1", val);
    }

    let staticc = dataURLtoFile(staticFile);

    let resizeImage = await resizeFile(val);

    formBody.append("uploadid", val);
    formBody.append("file2", staticc);

    let file_name_string = val.name;
    var file_name_array = file_name_string.split(".");
    var file_extension = file_name_array[file_name_array.length - 1];

    if (!val.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Modal.info({
        title: "Format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",
        onOk() {},
      });
      return false;
    }

    if (file_extension === "HEIC" || file_extension === "heic") {
      setIsModalError(true);

      heic2any({
        blob: val,
        toType: "image/jpg",
      })
        .then((res) => {})
        .catch((err) => {});

      Modal.info({
        title: "format tidak sesuai silahkan upload file .png/.jpeg/.jpg",
        content: "",

        onOk() {},
      });

      return;
    } else {
      setLoadingUp(true);
      setLoading_up(true);
      network.post(
        
          'https://c478-95-111-193-212.ngrok-free.app/api/image',
        
        formBody,
        (res) => {
          utils.saveLocalStoreage("_originImage", res.values.img_origin);
          utils.saveLocalStoreage("_image", res.values.processed_image);
          utils.saveLocalStoreage("_originImageMobile", res.values.img_origin);
          utils.saveLocalStoreage("_imageMobile", res.values.processed_image);
          utils.saveLocalStoreage("is_upload", true);
          setTempIsUpload(true);
          // console.log(res);
          // console.log(res.values.img_origin);
          // console.log(res.values.processed_image);
          window.location.href = "/experiment";

          if (reload) {
            callback();
          }
        },
        (err) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoadingUp(false);
          setLoading_up(true);
        }
      );
    }
  };


  return (
    <>
      <div
        className="home__upload text-left "
        style={{ background: "#E84A24", borderRadius: "0" }}
      >
        <div>
          <div className="upload__wrapper border-sm py-md-2 py-1 px-md-3 px-2">
            <div
              className={
                errorInput.file !== ""
                  ? window.innerWidth > 768
                    ? "video-dotline-err border-sm"
                    : ""
                  : window.innerWidth > 768
                  ? "video-dotline border-sm"
                  : ""
              }
            >
              {file !== null && !lp ? (
                <>
                  <div className="d-none d-md-block">
                    <span className="fontw-8 fonts-16 fc-white d-md-block d-none">
                      {fileName}
                    </span>
                    <Button onClick={deleteFile} type="primary" danger>
                      X
                    </Button>
                  </div>
                  <div className="d-md-none">
                    <div
                      className="text-danger font-weight-bold"
                      onClick={deleteFile}
                    >
                      X
                    </div>
                  </div>
                </>
              ) : (
                !lp && (
                  <>
                    <input
                      type="file"
                      className="video-upload"
                      onChange={getFile}
                      accept="image/*"
                    />
                    <span className="upl-img text-center d-flex  flex-column align-items-center">
                      <img
                        src={IC_download}
                        className="ic__download d-none d-md-block"
                      />
                      <img
                        src={IC_download}
                        className="ic__download d-md-none"
                        style={{ width: "auto", height: "14px" }}
                      />
                      <div
                        className="d-md-none d-block text-white"
                        style={{ fontSize: "11px", fontWeight: 400 }}
                      >
                        {t("Unggah")}
                      </div>
                    </span>
                    <span className="fc-white text-white d-none d-md-block">
                      {t("Unggah foto ruangan Anda")}
                    </span>
                    {fileSize === true ? (
                      <span className="" style={{ color: "red" }}>
                        {t("Ukuran file tidak boleh lebih dari")} 10mb
                      </span>
                    ) : null}
                  </>
                )
              )}
              <div
                className="text d-none d-md-block"
                style={{ color: "white" }}
              >
                Max. 10MB (.png, .jpg, .jpeg)
              </div>
              {lp ? (
                <>
                  <div className="d-md-none">
                    <div
                      className="text-danger font-weight-bold"
                      onClick={deleteFile}
                    >
                      X
                    </div>
                  </div>
                </>
              ) : null}
              <div
                className="w-full relative d-none d-md-block"
                style={{ height: "10px" }}
              >
                <div
                  ref={loadingRef1}
                  className="progress__bar absolute backg-white border-sm h-10"
                >
                  <div
                    ref={loadingRef2}
                    className="progress__bar progress__move absolute backg-secondary border-sm h-10"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!loadingUp || <OverlayLoading />}
       {/* Disclaimer Modal */}
    <Modal
      title="Disclaimer"
      visible={isDisclaimerModalVisible}
      footer={null} // Hide footer if no action is needed
      onCancel={() => setIsDisclaimerModalVisible(false)} // Allow users to manually close if they wish
    >
      <p>{t("Hasil akhir mungkin tidak sempurna, bergantung pada kejelasan gambar yang diupload")}</p>
    </Modal>
      {/* <OverlayLoading /> */}
    </>
  );
  // return (
  //   <>
  //     <div
  //       className="home__upload text-left"
  //       style={{ background: "#E84A24", borderRadius: "0" }}
  //     >
  //       <div>
  //         <div className="upload__wrapper border-sm py-md-2 py-1 px-md-3 px-2">
  //           <div
  //             className={
  //               errorInput.file !== ""
  //                 ? window.innerWidth > 768
  //                   ? "video-dotline-err border-sm"
  //                   : ""
  //                 : window.innerWidth > 768
  //                 ? "video-dotline border-sm"
  //                 : ""
  //             }
  //           >
  //             {file !== null && !lp ? (
  //               <>
  //                 <div className="d-none d-md-block">
  //                   <span className="fontw-8 fonts-16 fc-white d-md-block d-none">
  //                     {fileName}
  //                   </span>
  //                   <Button onClick={deleteFile} type="primary" danger>
  //                     X
  //                   </Button>
  //                 </div>
  //                 <div className="d-md-none">
  //                   <div
  //                     className="text-danger font-weight-bold"
  //                     onClick={deleteFile}
  //                   >
  //                     X
  //                   </div>
  //                 </div>
  //               </>
  //             ) : (
  //               !lp && (
  //                 <>
  //                   {/* Upload button triggers modal instead of opening file dialog directly */}
  //                   <div className="upload-btn" onClick={() => setIsDisclaimerModalVisible(true)}>
  //                   <span className="upl-img text-center d-flex  flex-column align-items-center">
  //                     <img
  //                       src={IC_download}
  //                       className="ic__download d-none d-md-block"
  //                     />
  //                     <img
  //                       src={IC_download}
  //                       className="ic__download d-md-none"
  //                       style={{ width: "auto", height: "14px" }}
  //                     />
  //                       <div
  //                       className="d-md-none d-block text-white"
  //                       style={{ fontSize: "11px", fontWeight: 400 }}
  //                     >
  //                       {t("Unggah")}
  //                     </div>
  //                   </span>
  //                   <span className="fc-white text-white d-none d-md-block">
  //                     {t("Unggah foto ruangan Anda")}
  //                   </span>
  //                   </div>
  //                   {fileSize === true && (
  //                     <span style={{ color: "red" }}>{t("Ukuran file tidak boleh lebih dari")} 10mb</span>
  //                   )}
  //                 </>
  //               )
  //             )}
  //             <div className="text d-none d-md-block" style={{ color: "white" }}>
  //               Max. 10MB (.png, .jpg, .jpeg)
  //             </div>
  //             {lp ? (
  //               <>
  //                 <div className="d-md-none">
  //                   <div
  //                     className="text-danger font-weight-bold"
  //                     onClick={deleteFile}
  //                   >
  //                     X
  //                   </div>
  //                 </div>
  //               </>
  //             ) : null}
  //             <div className="w-full relative d-none d-md-block" style={{ height: "10px" }}>
  //               <div ref={loadingRef1} className="progress__bar absolute backg-white border-sm h-10">
  //                 <div ref={loadingRef2} className="progress__bar progress__move absolute backg-secondary border-sm h-10"></div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  
  //     {!loadingUp || <OverlayLoading />}
  //     {/* Disclaimer Modal */}
  //     <Modal
  //       title={t("Disclaimer")}
  //       visible={isDisclaimerModalVisible}
  //       className="custom-modal"
  //       wrapClassName="custom-modal-wrap"
  //       onOk={() => {
  //         setIsDisclaimerModalVisible(false);
  //         document.querySelector('.video-upload').click(); // Trigger the hidden file input
  //       }}
  //       onCancel={() => setIsDisclaimerModalVisible(false)}
  //     >
  //       <p>{t("Hasil akhir mungkin tidak sempurna, bergantung pada kejelasan gambar yang diupload")}</p>
  //     </Modal>
  //     {/* Hidden file input for actual file selection */}
  //     <input
  //       type="file"
  //       className="video-upload"
  //       onChange={getFile}
  //       accept="image/*"
  //       style={{ display: 'none' }}
  //     />
  //   </>
  // );
  
};
