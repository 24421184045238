import { Route, Routes } from "react-router-dom";
import { CalculationPage } from "../pages/calculation/calculation";
import { NewCalculationPage } from "../pages/calculation/NewCal";
import IndexCategoryRumah from "../pages/category";
import CategoryRumah from "../pages/category/CategoryRumah";
import { IndexExperimentPage } from "../pages/experiment";
import { Desktopv2 } from "../pages/experiment/desktopv2";
import { ExperimentPage } from "../pages/experiment/experiment";
import { Index2ExperimentPage } from "../pages/experiment/index2";
import { ShareTaco } from "../pages/experiment/shareTaco";
import StatisNewLayout from "../pages/experiment/StatisNewLayout";
import IndexHome from "../pages/home";
import HomePage from "../pages/home/home";
import NewHome from "../pages/home/NewHome";
import LabGL from "../pages/labs/webgl";
import NewPrespektif from "../pages/perspektif/newPerspektif";
import Perspektif from "../pages/perspektif/perspektif";
import RoomDetailPage from "../pages/room-detail/room-detail";
import Welcome from "../pages/welcome/Welcome";

const MainRoutes = () => {
  const routeList = [
    {
      path: "/room/:id",
      exact: true,
      element: <IndexCategoryRumah />,
    },
    {
      path: "/experiment/statis",
      exact: true,
      element: <StatisNewLayout />,
    },
    {
      path: "/experiment/",
      exact: true,
      element: <IndexExperimentPage />,
    },
    {
      path: "/share",
      exact: true,
      element: <Index2ExperimentPage />,
    },
    {
      path: "/experiment/share",
      exact: true,
      element: <ShareTaco />,
    },

    // {
    //   path: "/calculation",
    //   exact: true,
    //   element: <CalculationPage />,
    // },
    {
      path: "/calculation",
      exact: true,
      element: <NewCalculationPage />,
    },
    {
      path: "/setting-perspektif",
      exact: true,
      element: <Perspektif />,
    },
    {
      path: "/setting-perspektif-room",
      exact: true,
      element: <NewPrespektif />,
    },
    {
      path: "/",
      exact: true,
      element: <Welcome />,
    },
    {
      path: "/home",
      exact: true,
      element: <IndexHome />,
    },
  ];

  return (
    <Routes>
      {routeList.map(({ ...data }, idx) => (
        <Route {...data} key={idx} />
      ))}
    </Routes>
  );
};

export { MainRoutes };
