import React from "react";
import MobileHome from "./mobileHome";
import NewHome from "./NewHome";

export default function IndexHome() {
  const tagHtml = () => {
    if (window.innerWidth > 768) {
      return (
        <>
          {/* <CategoryRumah /> */}
          <NewHome />
        </>
      );
    } else {
      return (
        <>
          <MobileHome />
        </>
      );
    }
  };
  return <>{tagHtml()}</>;
}
