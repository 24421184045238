import "../../assets/css/bootstrap.min.css";
import "../../index.css";
import "./newlayout.css";
import AliceCarousel from "react-alice-carousel";
import "./experiment.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { Card, Col, Image, Modal, Row, Select, Tooltip } from "antd";
import NavButtonExperiment from "../../components/experiment/nav-button";
import NavButtonExperimentNew from "../../components/experiment/nav-button-new";
import { useSpring } from "react-spring/three";
import React, { useState, useEffect, createRef, useCallback } from "react";
import { Canvas } from "react-three-fiber";
import "./styles.css";
import utils from "../../utility/utils";
import { CanvasImage } from "../../components/experiment/canvas-image";
import { OverlayLoading } from "../../components/shared/overlay-loading";
import { skeletonItems } from "../../components/experiment/skeleton-items";
import { network } from "../../utility/network";
import square_taco from "../../assets/images/taco-square.webp";
import { Option } from "antd/lib/mentions";
import { toPng } from "html-to-image";
import { ActionButton } from "../../components/experiment/action-button";
import { UploadField } from "../../components/shared/upload_field";
import { Link, useSearchParams } from "react-router-dom";
import watermark from "../../assets/images/logo.png";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import { ExperimentAction } from "../../components/experiment/experimen-action";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";
import arrowLeft from "../../assets/icons/arrow-left-2.png";
import { ExperimentActionV2 } from "../../components/experiment/experimen-action-v2";
import { useTranslation } from "react-i18next";
import Back from "../../components/back/back";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";
import IC_Cal from "../../assets/icons/calculate.png";
import Alert from 'react-bootstrap/Alert';  
import { Container } from 'react-bootstrap';


export default function NewLayout() {
  const { t, i18n } = useTranslation();

  const [floors, setFloors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [patterns, setPatterns] = useState([]);
  const [patternsAssets, setPatternsAssets] = useState([]);
  const [showed, setShowed] = useState("category");
  const [choosedFloor, setChoosedFloor] = useState(null);

  const [image, setImage] = useState("");
  const [originImage, setOriginImage] = useState("");
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const [styleWidth, setStyleWidth] = useState({});
  const [loadingUp, setLoadingUp] = useState(false);

  const [valueZoom, setValZoom] = useState(0);
  const [valueArah, setValArah] = useState(0);
  const [tempNameCategory, setTempNameCategory] = useState(null);
  const [readyCalc, setReadyCalc] = useState(false);

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [0.9, 0.9, 0.9],
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const [{ rotation }, setRotation] = useSpring(() => ({
    // positioning the floor [X, Y, Z]
    // -50, biar kemiringannya pas defaultnya
    rotation: [-50, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  var constAngle = 0;
  var constZoom = -50;

  useEffect(() => {
    console.log("DESKTOP VIEW");
  });

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  const getArah = (id) => {
    network.post(
      `detail-perspektif`,
      {
        room_id: utils.fetchLocalStorage("_idRoom"),
        produk_id: id,
      },
      (res) => {
        if (res.data) {
          handleMotif(res.data.posisi_motif, res.data.arah_motif);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleMotif = (posisi, arah) => {
    setValArah(arah);
    for (let index = 0; index < arah; index++) {
      if (index > 0) {
        handleChangeSlider(index);
      }
    }

    setValZoom(posisi + 1);
    for (let index = 88; index < posisi; index++) {
      if (index > 0) {
        handleArrangeFloor(index);
      }
    }
  };

  // slider arah
  const handleChangeSlider = (val) => {
    setValArah(val);
    // handle rotate image
    const angle = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [constZoom, 0, angle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constAngle = angle;
  };

  const handleArrangeFloor = (val) => {
    // handle zoom image
    const zoom = val + ((val * 10) / window.innerHeight) * 180; // 550 for ipad

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [zoom, 0, constAngle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constZoom = zoom;
  };

  const handleArrangeFloorIpad = (val) => {
    setValZoom(val + 1);
    if (imgWidth > imgHeight) {
      // handle zoom image
      const zoom = val + ((val * 5) / window.innerHeight) * 540; // 550 for ipad

      // for handle when user swipe the screen
      // it doesnt spin anywhere
      const vxyz = [0, 0, 0];

      // update the rotation
      setRotation({
        rotation: [zoom, 0, constAngle],
        immediate: true,
        config: { velocity: vxyz, decay: true },
      });
      constZoom = zoom;
    } else {
      // handle zoom image
      const zoom = val + ((val * 5) / window.innerHeight) * 640; // 550 for ipad

      // for handle when user swipe the screen
      // it doesnt spin anywhere
      const vxyz = [0, 0, 0];

      // update the rotation
      setRotation({
        rotation: [zoom, 0, constAngle],
        immediate: true,
        config: { velocity: vxyz, decay: true },
      });
      constZoom = zoom;
    }
  };

  const [imgWidth, setImageWidth] = useState(null);
  const [imgClient, setImageClient] = useState(null);
  const [imgHeightClient, setImageHeightClient] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);
  const [imageIsReady, setImageIsReady] = useState(false);

  const handleImageLoad = (event) => {
    // real code
    if (imgClient) {
      console.log("before return");
      return;
    }
    const imWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;

    if (imWidth < imgHeight) {
      setImageWidth(window.innerWidth);
      setImageHeight(window.innerHeight - 100);
    } else {
      setImageWidth(imWidth);
      setImageHeightClient(imgHeight);
    }

    if (selectedPattern === 0) {
      setImageClient(event.target.clientWidth);
      setImageHeight(event.target.clientHeight);
      console.log("selectedPattern === 0");
    } else {
      console.log("selectedPattern !== 0");
    }

    setImageIsReady(true);

    // hendleStyleCanvas();
  };

  const ref = createRef();

  // handle to download image
  const handleDownload = () => {
    setLoadingUp(true);
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        // Retrieve the selected product's name from the local storage
        const selectedProductName = utils.getLocalStoreage("product_name") || "TACO GAK";
        const prefix = "TACO Flooring "; 
        link.download = `${prefix}${selectedProductName}.png`;

        link.href = dataUrl;
        link.click();
      })
      .then((res) => {
        setLoadingUp(false);
      })
      .catch((err) => {
        console.log(err, "Error code");
        setLoadingUp(false);
      });
  };

  const handleCaptureClick = useCallback(async () => {
    setLoadingUp(true);
    const pricingTableElmt = document.querySelector(
      "#layout__desktop__download"
    );
    if (!pricingTableElmt) return;

    const canvas = await html2canvas(pricingTableElmt);

    const dataURL = canvas.toDataURL("image/png");

    // Retrieve the selected product's name from the local storage
    const selectedProductName = utils.getLocalStoreage("product_name") || "TACO GAK";
    const prefix = "TACO Flooring";

    // Set the desired file name
    const fileName = `${prefix}${selectedProductName}.png`;


    downloadjs(dataURL, fileName, "image/png");

    setLoadingUp(false);
  }, []);

  // screenshot the element
  // const getImage = () => takeScreenshot(ref.current).then(handleDownload);

  // choose floor to be set in CANVAS
  const handleChooseFloor = (floor) => {
    let imageFLoor = getBase64FromUrl(floor);
    handleLoading();
    setChoosedFloor(floor);
  };

  const fetchPattern = (id) => {
    network.get(
      `pattern-by-product/${id}`,
      {},
      (res) => {
        setPatterns(res.layouts);
        utils.saveLocalStoreage("layout_name", res.layouts[0].layout_name);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const fetchPatternAssets = (id) => {
    network.get(
      `asset`,
      { product_id: id },
      (res) => {
        if (res.data.length > 0) {
          let imgURL = process.env.REACT_APP_STORAGE_URL + res.data[0].image;

          handleChooseFloor(imgURL);
          setPatternsAssets(res.data);
          setSelectedPattern(res.data[0].id);
          utils.saveLocalStoreage("select_product", imgURL);
          utils.saveLocalStoreage("asset_id", res.data[0].id);
          utils.saveLocalStoreage("_pattern_id", res.data[0].layout_id);
          utils.saveLocalStoreage("isCalc", true);
          setReadyCalc(true);
        } else {
          setReadyCalc(false);
          utils.saveLocalStoreage("isCalc", false);
          utils.messageError("Tidak ada asset pattern untuk product ini!");
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  // choose floor to be set in CANVAS
  const handleSelectProduct = (id, name) => {
    utils.saveLocalStoreage("_product_id", id);
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);

    fetchPattern(id);
    fetchPatternAssets(id);
    setSelectedProduct(id);

    utils.saveLocalStoreage("product_name", name);

    getArah(id);
  };

  useEffect(() => {
    if (searchParams.has("k") && searchParams.has("n")) {
      handleSelectCategory(searchParams.get("k"), searchParams.get("n"));
    }
  }, []);

  // select category and show the product
  const handleSelectCategory = (id, name) => {
    setTempNameCategory(name);
    setShowed("products");
    setLoading(true);
    network.get(
      `kategori/${id}`,
      {},
      (res) => {
        utils.saveLocalStoreage("_category_id", id);
        setProducts(res.data.product);
        setShowed("products");

        if (searchParams.has("q")) {
          handleSelectProduct(
            parseInt(searchParams.get("q")),
            utils.fetchLocalStorage("product_by_name")
          );
        }
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const hendleStyleCanvas = () => {
    if (550 <= window.innerWidth && window.innerWidth < 800) {
      setStyleWidth({ width: `100%`, height: "450px" });
    } else {
      if (window.innerWidth < 550) {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `100%`, height: "250px" });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      } else {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `${imgClient ?? 0}px` });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      }
    }
  };

  const styleCanvasFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
        ? imgWidth >= imgHeight
          ? { width: `100%`, height: "250px" }
          : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
            border: "1px solid green",
          }
        : imgWidth >= imgHeight
          ? {
            width: `${imgClient ?? 0}px`,
            height: imgHeight,
            // border: "10px solid yellow",
          }
          : {
            width: `${imgClient ?? 0}px`,
            height: imgHeight,
            border: "4px solid yellow",
          };

  useEffect(() => {
    // get the room that user has choose or upload
    let res = utils.fetchLocalStorage("_image");
    let resOrigin = utils.fetchLocalStorage("_originImage");

    getBase64FromUrl(res).then((ress) => {
      let base64Image = `${ress}`;
      setImage(base64Image);
    });

    getBase64FromUrl(resOrigin).then((ress) => {
      let base64Image = `${ress}`;
      setOriginImage(base64Image);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    network.get(
      `list-kategori`,
      {},
      (res) => {
        setFloors(res.data);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );

    if (searchParams.has("token")) {
      network.get(
        `share`,
        { ref: searchParams.get("token") },
        (res) => {
          setChoosedFloor(res.data.url_layout);
          setImage(res.data.image);
        },
        (err, msg) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [searchParams]);

  const handleBackCarousel = () => {
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);
    if (showed === "bricks") {
      setShowed("products");
    } else if (showed === "products") {
      setShowed("category");
    }
  };

  const handleSelectPattern = (val) => {
    let res = patternsAssets.filter((item) => item.layout_id === val);

    if (res.length > 0) {
      let imgURL = process.env.REACT_APP_STORAGE_URL + res[0].image;
      utils.saveLocalStoreage("_pattern_id", val);
      utils.saveLocalStoreage("select_product", imgURL);

      let pattern = patterns.filter((item) => item.layout_id === val);
      if (pattern.length > 0) {
        utils.saveLocalStoreage("layout_name", pattern[0].layout_name);
      }

      handleChooseFloor(imgURL);
      setSelectedPattern(res[0].id);
    }
  };

  const LoadingSection = () => {
    let numberArray = [1, 2, 3, 3, 3, 3];
    return (
      <>
        <div className="mb-3 text-left fw-bold pilih__Kategori font-ghotam font-bold">
          {t("Kategori")}
        </div>
        {numberArray.map((item, index) => {
          return (
            <div className="row" key={index}>
              <div className="col-md-12 mb-2">
                <div
                  className="card__skeleton loading"
                  style={{ minHeight: "54px" }}
                ></div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  const LoadingSectionProduct = () => {
    let numberArray = [1, 2, 3, 3, 3, 3];
    return (
      <>
        <div className="select__product__item position-relative">
          <div className="row__product">
            <div className="row no-gutters  position-relative">
              {numberArray.map((item, index) => {
                return (
                  <div className="col-md-6 mb-2 p-1" key={index}>
                    <div
                      className="card__skeleton loading"
                      style={{ minHeight: "54px" }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const CategorySection = () => {
    return loading ? (
      <></>
    ) : floors.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <>
        <div className="mb-3 text-left fw-bold pilih__Kategori font-ghotam font-bold">
          {t("Kategori")}
        </div>
        <div className="row row__category__product no-gutters">
          {floors.map((item, i) => {
            return (
              <div className="col-md-12 mb-2" key={i}>
                <div
                  className={`card card__category position-relative d-flex align-items-center justify-content-center no-border-radius ${item.image ? "with__image__removejs" : ""
                    }`}
                  onClick={() => {
                    handleSelectCategory(item.id, item.name);
                  }}
                >
                  {/* {item.image ? (
                    <img
                      src={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                      alt=""
                      className="img-fluid img__category"
                    />
                  ) : null} */}
                  <div className="card__footer text-center text-white w-100 text-left  position-absolute">
                    {item.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const ProductsSection = () => {
    return products.length <= 0 ? (
      <>
        <h3 className="fc-white">
          <i>Data not found!</i>
        </h3>
      </>
    ) : (
      <>
        <div className="mb-3  text-left fw-bold pilih__Kategori d-none">
          <div className="d-flex align-items-center ">
            {tempNameCategory ?? ""}
          </div>
        </div>
        <div className="select__product__item position-relative">
          {showed !== "category" ? (
            <>
              <div
                className="mb-2 mb-md-0 kembali__product"
                style={{ position: "absolute", top: "-26px" }}
              >
                <div
                  className="d-flex align-items-center "
                  onClick={handleBackCarousel}
                  style={{ position: "relative" }}
                >
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <path
                      d="M8.07477 15.5L0 8L8.07477 0.5L10 2.28819L3.85047 8L10 13.7118L8.07477 15.5Z"
                      fill="black"
                      fillOpacity="0.4"
                    />
                  </svg>

                  <span className="ml-2 cursor-pointer kembali font-bold">
                    {t("KEMBALI")}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="row__product">
            <div
              className="row no-gutters  position-relative "
              style={
                products.length > 6 ? { height: "calc(100vh - 519px)" } : null
              }
            >
              {/* <div className="col-12"> */}

              {/* </div> */}
              {products.map((item, i) => {
                return (
                  <div className="col-md-6 p-1" key={i}>
                    <div
                      className={`card card__product  position-relative d-flex flex-column align-items-center justify-content-center no-border-radius ${item.id === selectedProduct ? "active" : ""
                        }`}
                      style={{
                        background: `url(${process.env.REACT_APP_STORAGE_URL + item.image
                          })`,
                      }}
                      onClick={() => {
                        handleSelectProduct(item.id, item.sku);
                      }}
                    >
                      {/* <img
                        src={process.env.REACT_APP_STORAGE_URL + item.image}
                        className="img-fluid img__product"
                        alt=""
                      /> */}
                      <div className="card__footer text-center text-white w-100 text-left  position-absolute font-bold">
                        {item.sku}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const createShareLink = () => {
    let form = {
      image_url: utils.fetchLocalStorage("_image"),
      product_id: selectedProduct,
      asset_id: selectedPattern,
      url_layout:
        choosedFloor === null
          ? patternsAssets.length === 0
            ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
            : process.env.REACT_APP_STORAGE_URL + patternsAssets[0].image
          : choosedFloor,
    };

    if (utils.fetchLocalStorage("is_upload")) {
      form.is_upload = 1;
    }

    network.post(
      `create-share-link`,
      form,
      (res) => {
        setTokenShare(res.data.ref);
        triggerModalShare(res.data);
        utils.saveLocalStoreage("_share_image_id", res.data.id);
      },
      (err) => { },
      () => { }
    );
  };

  const triggerModalShare = () => {
    setisShareModalShow(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalShow, setisShareModalShow] = useState(false);
  const [tokenShare, setTokenShare] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const [loadingImage, setLoadingImage] = useState(false);

  const handleLoading = () => {
    let lprogress = setTimeout(() => {
      clearInterval(lprogress);
    }, 2000);
  };

  // *********** return data
  return (
    <>
      <Modal
        footer={false}
        className="modal__experiment"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <UploadField
          reload={true}
          callback={() => {
            let res = utils.fetchLocalStorage("_image");
            let resOrigin = utils.fetchLocalStorage("_originImage");
            setImage(res);
            setOriginImage(resOrigin);
            setIsModalVisible(false);
          }}
        />
      </Modal>

      {/* Modal share */}
      <Modal
        onCancel={() => setisShareModalShow(false)}
        footer={false}
        className="modal__experiment"
        onOk={() => setisShareModalShow(false)}
        visible={isShareModalShow}
      >
        <h4 className="fontw-6 fc-white">{t("Bagikan hasil eksperimen!")}</h4>
        <div className="exp__copy__link fontw-6">
          {window.location.href + "?token=" + tokenShare}
        </div>

        <div className="mt-3 fc-white">{t("Atau bagikan melalui :")}</div>
        <div className="d-flex mt-1">
          <FacebookShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <FacebookIcon className="exp__social__btn" />
          </FacebookShareButton>
          <WhatsappShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <WhatsappIcon className="exp__social__btn" />
          </WhatsappShareButton>
          <TwitterShareButton
            url={window.location.href + "?token=" + tokenShare}
          >
            <TwitterIcon className="exp__social__btn" />
          </TwitterShareButton>
          <LineShareButton url={window.location.href + "?token=" + tokenShare}>
            <LineIcon className="exp__social__btn" />
          </LineShareButton>

          <Tooltip placement="top" title="Tersalin!" trigger="click">
            <div
              className="exp__social__btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.href}?token=${tokenShare}`
                );
              }}
            >
              <LinkOutlined />
            </div>
          </Tooltip>
        </div>
      </Modal>

      <div className="overflow-x-hidden">
        <div className="row no-gutters justify-content-between ">
          <div className="col-md-4 layout__right padding__new_layout">
            <div className="mb-5">
            <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
            </div>

            {!searchParams.has("token") && selectedPattern !== 0 ? (
              <></>
            ) : (
              <></>
            )}
            <div
              className={`${showed === "category"
                ? "kategori__harusnya"
                : products.length > 6
                  ? "height_calc__layout"
                  : products.length < 5
                    ? "height_calc__layout"
                    : null
                } position-relative ${products.length <= 6 && showed !== "category"
                  ? "d-flex flex-column justify-content-between"
                  : ""
                }`}
            >
              {showed === "category" ? (
                <div
                  className="mb-2 mb-md-0"
                  style={{ position: "absolute", top: "-26px" }}
                >
                  <Back />
                </div>
              ) : (
                <></>
              )}

              {searchParams.has("token") || (
                <>
                  {showed === "category" ? (
                    loading ? (
                      <>
                        <LoadingSection />
                      </>
                    ) : (
                      <CategorySection />
                    )
                  ) : (
                    <>
                      <div className="">
                        {loading ? (
                          <LoadingSectionProduct />
                        ) : (
                          <>
                            <ProductsSection />
                          </>
                        )}
                        {patterns.length === 0 ||
                          patternsAssets.length === 0 || (
                            <>
                              <div className="w-100 mb-2 mt-2 ">
                                <Select
                                  className="w-100 select_experiment"
                                  defaultValue={patterns[0].layout_id}
                                  onChange={handleSelectPattern}
                                  showArrow={false}
                    dropdownClassName="no-border-radius" // Add the custom class name here

                                >
                                  {patterns.map((item, idx) => (
                                    <Option value={item.layout_id} key={idx}>
                                      <div className="row no-gutters">
                                        <div className="col-8">
                                          <Image
                                            preview={false}
                                            src={
                                              process.env
                                                .REACT_APP_STORAGE_URL +
                                              item.layout_image
                                            }
                                            width={"100%"}
                                            height={49}
                                            alt="empty taco"
                                          />
                                        </div>
                                        <div className="col-4">
                                          <div className="bg__dark d-flex justify-content-center align-items-center">
                                            <p className="pl-2 mb-0 ">
                                              {item.layout_name}
                                            </p>
                                            {/* <div className="">
                                            <DownOutlined />
                                          </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </>
                          )}
                      </div>

                      {patterns.length === 0 || patternsAssets.length === 0 || (
                        <div className="">
                          {selectedProduct !== 0 ? (
                            <>
                              <div className="header_cal  mt-4">
                                {readyCalc ? (
                                  <Link
                                    to={"/calculation"}
                                    className="calculate calculate__desktop w-100"
                                  >
                                    {/* <img
                                  src={IC_Cal}
                                  alt=""
                                  style={{ width: "30px" }}
                                /> */}
                                    {t("Kalkulasi Lantai")}
                                  </Link>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="w-100">
                                <ActionButton
                                  canUpload={!searchParams.has("token")}
                                  callbackDownload={handleDownload}
                                  shareable={selectedPattern !== 0}
                                  callbackShare={createShareLink}
                                  callbackUpload={showModal}
                                  className=" d-sm-flex d-none"
                                />
                              </div>
                              <div className="App">  
   <div className="mt-2">
   <Alert variant="warning" style={{ fontSize: '10px', width: '100%', paddingLeft: '12px', paddingRight: '12px', display: 'flex', alignItems: 'center' }}>
  {t("⚠️Preview yang ditampilkan bersifat indikatif dan tidak merupakan representasi yang akurat dari warna dan tekstur produk sebenarnya")}
</Alert>
 
  </div>
</div> 
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="col-md-8 pl-5">
            <div
              className=""
              id="layout__desktop__download "
              style={{
                position: "relative",
                overflow: "hidden",
                maxHeight: "100vh",
                height: "100vh",
                width: "100%",
              }}
              ref={ref}
            >
              <div className="canvas__field " style={styleCanvasFrame}>
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                          patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {image === "" || (
                  <img
                    className="watermark_image d-none"
                    src={watermark}
                    alt="empty watermark"
                  />
                )}
              </div>

              <div className="position-relative h-100">
                <img
                  src={
                    choosedFloor === null && patternsAssets.length === 0
                      ? originImage
                      : image
                  }
                  onLoad={handleImageLoad}
                  alt="taco empty new-layout"
                  className={"img-fluid image__rooom"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
