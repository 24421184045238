import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./category.css";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";

import IC_download from "../../assets/icons/download.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link, useParams } from "react-router-dom";
import { network } from "../../utility/network";
import { useDraggable } from "react-use-draggable-scroll";
import Back from "../../components/back/back";
import RoomCategory from "../../components/category/RoomCategory";
import { NewUploadField } from "../../components/shared/newUpload";
import ChangeLanguage from "../../components/change-language/changeLanguage";
import { useTranslation } from "react-i18next";
import utils from "../../utility/utils";

export default function NewCategoryRumah() {
  const [tempData, setTempData] = useState({});
  const [room, setRoom] = useState([]);
  const { t, i18n } = useTranslation();
  const [loading_up, setLoading_up] = useState(false);
  const [hLogo, setHlogo] = useState(0);

  let { id } = useParams();
  const ref = useRef();
  const refLogo = useRef();
  const { events } = useDraggable(ref);

  useEffect(() => {
    setHlogo(refLogo.current.offsetHeight);
  }, []);

  useEffect(() => {
    network.get(`home/${id}`, {}, (res) => {
      setTempData(res.data[0]);
      setRoom(res.data[0].room);
    });
  }, []);

  useEffect(() => {
    console.log("tempData", !tempData.length);
    console.log("tempData", tempData);
  }, [tempData]);

  const tagImageCarousel = () => {
    return (
      <>
        {room.length > 6 ? (
          <>
            <div className="col-md-12  col-kosong d-none d-md-block"></div>
          </>
        ) : (
          <></>
        )}
        {room.map((item, index) => {
          // console.log("room.length - 1", room.length - 1);
          return (
            <RoomCategory
              key={index}
              type={item.room_categori_id}
              loading_upload={loading_up}
              keyIndex={index}
              title={item.name}
              title_en={item.name_en}
              class_prop={
                room.length - 1 === index || room.length - 2 === index
                  ? ""
                  : "mb-2"
              }
              id={item.id}
              imgURL={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
              imgUrlMobile={`${process.env.REACT_APP_STORAGE_URL}${
                item.image_mobile === null ? item.image : item.image_mobile
              }`}
              originImage={`${process.env.REACT_APP_STORAGE_URL}${item.image_full}`}
              originImageMobile={`${process.env.REACT_APP_STORAGE_URL}${
                item.image_full_mobile === null
                  ? item.image_full
                  : item.image_full_mobile
              }`}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className=" " style={{ zIndex: 10, overflowX: "hidden" }}>
        <div className="">
          <div className="row no-gutters justify-content-between ">
            <div
              className="col-md-12 deskripsi  "
              // style={
              //   window.innerWidth > 768 ? { height: "calc(100vh - 155px)" } : null
              // }
            >
              <div className="row no-gutters h-100vh">
                <div className="col-4 d-flex flex-column justify-content-between padding__new_layout position-static  ">
                  <div className="">
                    <div
                      className="mb-md-5 d-flex justify-content-between align-items-center"
                      ref={refLogo}
                    >
          <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
                      <div
                        className="pr-new"
                        style={{ position: "absolute", right: 0, zIndex: 20 }}
                      >
                        <ChangeLanguage />
                      </div>
                      {/* <div className="d-md-block d-none"></div> */}
                      <div
                        className="d-md-none
            "
                      >
                        <NewUploadField setLoading_up={setLoading_up} />
                      </div>
                    </div>
                    <div className="position-relative">
                      <div
                        className="mb-2 mb-md-0"
                        style={{
                          position: "absolute",
                          top: "-26px",
                          zIndex: 20,
                        }}
                      >
                        <Back />
                      </div>
                      <h1
                        className="font-ghotam font-bold title__category"
                        style={{ marginBottom: 0, lineHeight: 1 }}
                      >
                        {utils.languangeT(tempData.name, tempData.name_en)}
                      </h1>
                      <p
                        className="mt-3 text"
                        style={
                          tempData.decription
                            ? tempData.decription.length > 130
                              ? { fontSize: "20px" }
                              : { fontSize: "23px" }
                            : null
                        }
                      >
                        {utils.languangeT(
                          tempData.decription,
                          tempData.decription_en
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-md-block d-none">
                    <NewUploadField setLoading_up={setLoading_up} />
                  </div>
                </div>

                <div
                  className="col-md-8 pl-5 "
                  style={{ paddingBottom: " 2.35cm", paddingTop: "2.35cm" }}
                >
                  <div
                    className={`row no-gutters h-100 carousel_slide ${
                      room.length >= 6 ? "h-100-vh" : ""
                    }`}
                    {...events}
                    ref={ref}
                    style={{ paddingTop: `calc(${hLogo}px + 3rem + 13px)` }}
                  >
                    {tagImageCarousel()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
