import React from "react";
import CategoryRumah from "./CategoryRumah";
import MobileCategory from "./mobile";
import NewCategoryRumah from "./NewCategory";

export default function IndexCategoryRumah() {
  const tagHtml = () => {
    if (window.innerWidth > 768) {
      return (
        <>
          {/* <CategoryRumah /> */}
          <NewCategoryRumah />
        </>
      );
    } else {
      return (
        <>
          <MobileCategory />
        </>
      );
    }
  };
  return <>{tagHtml()}</>;
}
