import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { Desktop } from "./desktop";
import { Desktopv2 } from "./desktopv2";
import { ExperimentPage } from "./experiment";
import NewLayout from "./newlayout";
import ShareExp from "./share";

export const Index2ExperimentPage = () => {
  const [searchParams] = useSearchParams();
  const tagHtml = () => {
    if (searchParams.has("token")) {
      return <ShareExp />;
    }
    if (window.innerWidth > 1024) {
      if (searchParams.has("token")) {
        return <ShareExp />;
      } else {
        return (
          <div className="image__desktop">
            <Desktopv2 />
          </div>
        );
      }
    } else if (window.innerWidth < 1054 && window.innerWidth > 768) {
      //untuk ipad
      return (
        <div className="image__ipad">
          <Desktop />
        </div>
      );
    } else {
      return (
        <div className="image__mobile">
          <ExperimentPage />
        </div>
      );
    }
  };

  return <>{tagHtml()}</>;
};
