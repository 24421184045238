import "./navigation.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const NavButtonExperiment = () => {
  const history = useNavigate();

  const clickBack = () => {
    history(-1);
    // <Navigate to="/" replace={true} />;
  };

  return (
    <div className="fixed experiment__button d-flex justify-between w-full px-4">
      <div>
        <div
          onClick={clickBack}
          className="cursor-pointer d-flex align-center justify-around backg-primary fontw-8 fc-white border-lg pr-3"
          style={{ height: "auto" }}
        >
          <span className="d-flex">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6388 18L24.48 24.7184L21.0607 28.08L10.8 18L21.0607 7.92001L24.48 11.2816L17.6388 18Z"
                fill="white"
              />
            </svg>
          </span>
          Kembali
        </div>
      </div>

      <Link to="/calculation">
        <div
          className="cursor-pointer d-flex align-center text-center backg-primary fontw-8 fc-white border-lg py-1 px-4"
          style={{ height: "auto" }}
        >
          Hitung Lantai
        </div>
      </Link>
    </div>
  );
};

export default NavButtonExperiment;
