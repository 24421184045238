import { Button, Col, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { network } from "../../utility/network";
import utils from "../../utility/utils";
import "./style.css";

export const NewLeft = ({ callback, callbackLoading }) => {
  const [chooseCategory, setChooseCategory] = useState();
  const [category, setProduct] = useState();
  const [categories, setCategories] = useState([]);

  const [panjang, setPanjang] = useState(0);
  const [lebar, setLebar] = useState(0);
  const [errpanjang, setErrPanjang] = useState(false);
  const [errMsgpanjang, setErrMsgPanjang] = useState("");
  const [errlebar, setErrLebar] = useState(false);
  const [errMsglebar, setErrMsgLebar] = useState("");
  const [errcategory, setErrCategory] = useState(false);
  const [errMsgcategory, setErrMsgCategory] = useState("");
  const [errpattern, setErrPattern] = useState(false);
  const [errMsgpattern, setErrMsgPattern] = useState("");
  const [bricks, setBricks] = useState([]);

  const [local_cat, setLocalCat] = useState(0);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  useEffect(() => {
    let category_id = parseInt(utils.fetchLocalStorage("_category_id"));
    let pattern_id = utils.fetchLocalStorage("_pattern_id");

    let cat_list = [];

    network.get(
      "list-kategori",
      {},
      (res) => {
        setCategories(res.data);
        cat_list = res.data;
      },
      (err) => {},
      () => {
        callbackLoading();
      }
    );

    if (utils.isNotEmpty(category_id)) {
      setLocalCat(category_id);

      // get category
      network.get(
        `kategori/${category_id}`,
        {},
        (res) => {
          setBricks(res.data);

          // pilih asset berdasar pattern id dr local storage
          let temp = res.data.variant.filter(
            (item) => item.layout_id === pattern_id
          );
          if (temp.length > 0) {
            handleSelectProduct(temp[0]);

            let result = cat_list.filter((item) => item.id === category_id);

            if (result.length > 0) {
              setChooseCategory(result[0]);
            }
          }
        },
        (err) => {},
        () => {
          callbackLoading();
        }
      );
    }
  }, [callbackLoading]);

  const fetchBricks = (val) => {
    setLoading(true);
    network.get(
      `kategori/${val}`,
      {},
      (res) => {
        setBricks(res.data);
      },
      (err) => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleSelectProduct = (val) => {
    setProduct(val);
  };

  // select category
  const handleSelectCategory = (val) => {
    fetchBricks(val);
    let res = categories.filter((item) => item.id === val);
    if (res.length > 0) {
      setChooseCategory(res[0]);
    }
  };

  const calculateBox = () => {
    if (
      !errlebar &&
      !errpanjang &&
      bricks.length !== 0 &&
      utils.isNotEmpty(category)
    ) {
      if (lebar !== 0 && panjang !== 0) {
        setErrCategory(false);
        setErrPattern(false);
        setErrPanjang(false);
        setErrLebar(false);

        let luas = panjang * lebar;
        let waste = category.layout.waste;
        let box = bricks.box;
        let divisor = waste / 100 + 1;
        let res = (luas / box) * divisor;

        callback({
          luas: luas,
          divisor: divisor,
          res: res,
          waste: waste,
          box: box,
          panjang: panjang,
          lebar: lebar,
          kategori: chooseCategory.name,
        });
      } else {
        if (panjang === 0) {
          setErrPanjang(true);
          setErrMsgPanjang(t("Kolom harus diisi!"));
        }
        if (lebar === 0) {
          setErrLebar(true);
          setErrMsgLebar(t("Kolom harus diisi!"));
        }
      }
    } else {
      if (panjang === 0) {
        setErrPanjang(true);
        setErrMsgPanjang(t("Kolom harus diisi!"));
      }
      if (lebar === 0) {
        setErrLebar(true);
        setErrMsgLebar(t("Kolom harus diisi!"));
      }
      if (!utils.isNotEmpty(category)) {
        setErrPattern(true);
        setErrMsgPattern(t("Silahkan pilih polanya!"));
      }
      if (bricks.length === 0) {
        setErrCategory(true);
        setErrMsgCategory(t("Silahkan pilih kategori"));
      }
    }
  };

  return (
    <>
      <div>
        <div className="row no-gutters kolom-mobile">
          <div className="col-5 pr-3">
            <div
              className="h-100 position-relative bg__gradient_cal d-flex flex-column align-items-center justify-content-center"
              style={{
                background: `linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ),url(${utils.fetchLocalStorage("select_product")})`,
              }}
            >
              <div className="text-white text-center font-weight-bold">
                {utils.fetchLocalStorage("product_name")}{" "}
                <div className="" style={{ fontWeight: 400 }}>
                  {utils.fetchLocalStorage("layout_name")}
                </div>
              </div>
            </div>
          </div>

          <div className="col-7">
          
            <div className="mb-3 position-relative">
              <div className="text-lable mb-2 text-left">{t("Panjang")}</div>
              <div className="d-flex justify-content-between align-items-center">
                <Input
                  onChange={(e) => {
                    if (utils.validateNumber(e.target.value)) {
                      if (e.target.value > 100000) {
                        setErrPanjang(true);
                        setErrMsgPanjang("max number 100000");
                      } else {
                        setErrPanjang(false);
                        setPanjang(e.target.value);
                      }
                    } else {
                      setErrPanjang(true);
                      setErrMsgPanjang(t("Hanya terima format angka!"));
                    }
                  }}
                  className="ant__input__mobile no-border-radius"
                  placeholder={t("masukan angka")}
                  style={errpanjang ? { border: "1px red solid" } : null}
                />
                <div className="d-flex flex-column">
                  {!errpanjang || (
                    <small
                      className="fc-danger msg__error__mobile"
                      style={{ marginTop: 0 }}
                    >
                      {errMsgpanjang}
                    </small>
                  )}
                </div>
                <div className="meter fw-500">meter</div>
              </div>
            </div>
            <div className="mb-2 position-relative">
              <div className="text-lable mb-2 text-left">{t("Lebar")}</div>
              <div className="d-flex justify-content-between align-items-center">
                <Input
                  onChange={(e) => {
                    if (utils.validateNumber(e.target.value)) {
                      if (e.target.value > 100000) {
                        setErrLebar(true);
                        setErrMsgLebar("max number 100000");
                      } else {
                        setErrLebar(false);
                        setLebar(e.target.value);
                      }
                    } else {
                      setErrLebar(true);
                      setErrMsgLebar(t("Hanya terima format angka!"));
                    }
                  }}
                  className="ant__input__mobile no-border-radius"
                  placeholder={t("masukan angka")}
                  style={errlebar ? { border: "1px red solid" } : null}
                />
                <div className="d-flex flex-column">
                  {!errlebar || (
                    <small
                      className="fc-danger msg__error__mobile"
                      style={{ marginTop: 0 }}
                    >
                      {errMsglebar}
                    </small>
                  )}
                </div>
                <div className="meter fw-500">meter</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={calculateBox}
        className={`btn btn__hitung  py-2  no-border-radius`}
        style={errlebar ? { marginTop: "2rem" } : { marginTop: "1.5rem" }}
      >
        {t("Hitung")}
      </div>
    </>
  );
};
