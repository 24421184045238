import { TextureLoader, Math as ThreeMath, RepeatWrapping } from "three";
import { useSpring, useTransition, config } from "react-spring/three";
import React, { useState, useMemo } from "react";
import { useThree } from "react-three-fiber";
import { CanvasTexture } from "./canvas-texture";
const { degToRad } = ThreeMath;
const loader = new TextureLoader();

export const CanvasImage = ({ url, rotation, ...props }) => {
  const [hovered, setHover] = useState(false);

  const { invalidate } = useThree();
  const textures = useMemo(() => {
    // loader.crossOrigin = "Anonymous";
    let texture1 = loader.load(url, invalidate);
    texture1.wrapS = RepeatWrapping;
    texture1.wrapT = RepeatWrapping;

    // repeating image
    // to set the floor size
    texture1.repeat.set(3, 3);
    return [
      {
        id: "front",
        texture: texture1,
        deg: 0,
      },
    ];
  }, [url, invalidate]);

  const { hoverValue } = useSpring({
    hoverValue: hovered ? 1 : 0,
    config: config.molasses,
  });

  const transitions = useTransition(textures, (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });

  return transitions.map(
    ({ item: { texture, deg }, props: { opacity }, key }) => (
      <CanvasTexture
        key={key}
        {...props}
        texture={texture}
        hoverValue={hoverValue}
        onHover={setHover}
        rotation={rotation.interpolate((x, y, z) => [
          degToRad(x),
          degToRad(y + deg),
          degToRad(z),
        ])}
        opacity={opacity}
      />
    )
  );
};
