import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import utils from "../../utility/utils";

export default function RoomCategory({
  id,
  title,
  imgURL,
  imgUrlMobile,
  type,
  originImage,
  originImageMobile,
  keyIndex,
  loading_upload,
  title_en,
  class_prop,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClickRoom = () => {
    utils.saveLocalStoreage("_image", imgURL);
    utils.saveLocalStoreage("_imageMobile", imgUrlMobile);
    utils.saveLocalStoreage("_originImage", originImage);
    utils.saveLocalStoreage("_originImageMobile", originImageMobile);
    utils.saveLocalStoreage("_idRoom", id);
    utils.saveLocalStoreage("_title", title);
    utils.saveLocalStoreage("_title_en", title_en);

    utils.saveLocalStoreage("is_upload", false);
    navigate(
      `/experiment${
        searchParams.has("q") && searchParams.has("k") && searchParams.has("n")
          ? "?q=" +
            searchParams.get("q") +
            "&k=" +
            searchParams.get("k") +
            "&n=" +
            searchParams.get("n")
          : ""
      }`
    );
  };

  return (
    <>
      <div
        className={`col-6 col__list__room  ${class_prop} ${
          keyIndex % 2 === 0 ? "pr-md-1" : "pl-md-1"
        }`}
        style={!loading_upload ? { zIndex: 20 } : null}
        onClick={handleClickRoom}
      >
        <div
          className="position-relative bg__gradient__room"
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.479167) 85.03%, rgba(0, 0, 0, 0.64) 100%), url(${originImage})`,
            cursor: "pointer",
          }}
        >
          <div className="name__room font-ghotam font-bold">
            {utils.languangeT(title, title_en)}
          </div>
        </div>
      </div>
    </>
  );
}
