import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { MainRoutes } from "./routes/routes";
import withClearCache from "./ClearCache";
import { useTranslation } from "react-i18next";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  const { t } = useTranslation();
  return <ClearCacheComponent />;
  // return <h1 className="text-white">{t("Welcome to React")}</h1>;
}

function MainApp(props) {
  return (
    <div className="App">
      <Router>
        <MainRoutes />
      </Router>
    </div>
  );
}

export default App;
