import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./new.css";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";
import IC_download from "../../assets/icons/download.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import utils from "../../utility/utils";
import { network } from "../../utility/network";
import { Link, useSearchParams } from "react-router-dom";
import { NewUploadField } from "../../components/shared/newUpload";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../components/change-language/changeLanguage";

export default function NewHome() {
  const [tempRoom, setTempRoom] = useState([]);
  const [tempTagItem, setTagItem] = useState([]);
  const [deskripsi, setDeskripsi] = useState({});
  const [loading_up, setLoading_up] = useState(false);
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [hLogo, setHlogo] = useState(0);

  const refLogo = useRef();
  useEffect(() => {
    setHlogo(refLogo.current.offsetHeight);
  }, []);

  useEffect(() => {
    network.get(`home`, {}, (res) => {
      setTempRoom(res.data);
      setDeskripsi(res.deskirpsi);
    });
  }, []);

  const responsive = {
    0: { items: 2 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    <>
      <div
        className=" d-flex"
        style={
          window.innerWidth > 768
            ? { overflowX: "hidden", overflowY: "hidden" }
            : { overflowX: "hidden" }
        }
      >
        <div className="row justify-content-between no-gutters h-100vh">
          <div
            className="col-md-4 deskripsi  new__font  padding__new_layout"
            style={{ flex: 1, position: "static" }}
          >
            <div className=" d-flex flex-column justify-content-between h-100 pb-md-0 pb-2">
              <div className="">
                <div
                  className="mb-md-5 d-flex justify-content-between align-items-center"
                  ref={refLogo}
                >
         <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
                  <div
                    className="pr-new no-border-radius"
                    style={{ position: "absolute", right: 0, zIndex: 20 }}
                  >
                    <ChangeLanguage />
                  </div>
                  {/* <div className="d-md-block d-none"></div> */}
                  <div
                    className="d-md-none
            "
                  >
                    <NewUploadField setLoading_up={setLoading_up} />
                  </div>
                </div>
                {/* <h1
                  className="font-ghotam font-bold mb-4"
                  style={{
                    fontSize: "65px",
                    marginBottom: 0,
                    lineHeight: 1,
                    paddingTop: "13px",
                  }}
                >
                  {t("FLOOR VISUALIZER")}
                </h1> */}
                <p className=" my-3 my-md-0 font-ghotam-book deskripsi__new">
                  <div
                    style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                    dangerouslySetInnerHTML={{
                      __html: utils.languangeT(
                        deskripsi.description,
                        deskripsi.decription_en
                      ),
                    }}
                  />
                  {/* {deskripsi} */}
                </p>
              </div>
              <div className="d-none d-md-block no-border-radius">
                <NewUploadField setLoading_up={setLoading_up} />
              </div>
            </div>
          </div>
          <div
            className="col-md-8 pl-5 item__category"
            style={{ paddingBottom: "2.35cm", paddingTop: "2.35cm" }}
          >
            <div
              className="row justify-content-end no-gutters h-100"
              style={{ paddingTop: `calc(${hLogo}px + 3rem + 13px)` }}
            >
              {tempRoom.slice(0, 2).map((item, index) => {
                return (
                  <div
                    className={`col-md-6  h-100 col__category ${
                      index > 0 ? "pl-md-1" : "pr-md-1"
                    }`}
                    key={index}
                  >
                    <div className="h-100 ">
                      <Link
                        to={`/room/${item.id}${
                          searchParams.has("q") &&
                          searchParams.has("k") &&
                          searchParams.has("n")
                            ? "?q=" +
                              searchParams.get("q") +
                              "&k=" +
                              searchParams.get("k") +
                              "&n=" +
                              searchParams.get("n")
                            : ""
                        }`}
                        className="h-100 d-block"
                      >
                        <div
                          className="kategori__home__new h-100  px-5"
                          style={{
                            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.479167) 29.17%, rgba(0, 0, 0, 0.64) 100%), url(${process.env.REACT_APP_STORAGE_URL}${item.image})`,
                          }}
                        >
                          <div className="d-none d-md-block"></div>
                          {/* <div className="">
                              {item.room.map((roomItem, idx) => {
                                return (
                                  <span key={idx}>
                                    {utils.languangeT(
                                      roomItem.name,
                                      roomItem.name_en
                                    )}{" "}
                                    <br />
                                  </span>
                                );
                              })}
                            </div> */}
                          <div className="type_kategori font-ghotam font-bold ">
                            {utils.languangeT(item.name, item.name_en)}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="" style={{ flex: 1 }}>
        </div> */}
      </div>
    </>
  );
}
