import { Slider } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import utils from "../../utility/utils";

export const ExperimentActionPrespektif = ({
  showed,
  showSlider,
  callbackArange,
  callbackSpin,
  callbackCarousel,
  imgWidth,
  imgHeight,
  token,
  propMin = null,
  arahVal = null,
}) => {
  const [searchParams] = useSearchParams();
  const [tempMinVal, setMinVal] = useState();
  const [tempMaxVal, setMaxVal] = useState();
  const [tempArahVal, setArahVal] = useState();

  const styleSlider =
    window.innerWidth < 800 ? { width: "120px" } : { width: "100%" };

  useEffect(() => {
    handleVal();

    if (propMin) {
      setMinVal(propMin);
    }

    if (arahVal) {
      setArahVal(arahVal);
    }
  }, [propMin, arahVal]);

  const handleVal = () => {
    if (propMin) {
      setMinVal(propMin);
    } else {
      const minVal =
        window.innerWidth < 330
          ? 76
          : window.innerHeight < 800
          ? window.innerWidth < 550
            ? imgHeight > imgWidth
              ? 90
              : 80
            : 88
          : 100;
      setMinVal(minVal);
    }

    const maxVal =
      window.innerWidth < 330
        ? 88
        : window.innerHeight < 800
        ? window.innerWidth < 550
          ? 100
          : 100
        : 115;

    setMaxVal(maxVal);
    if (arahVal) {
      setArahVal(arahVal);
    }
  };

  const tagSlider = () => {
    return (
      <div className="w-100 ">
        <div className="ml-2 text-left" style={styleSlider}>
          <p className="fc-white mb-0 text-left">Atur Posisi Motif</p>
          <Slider
            onChange={callbackArange}
            tooltipVisible={false}
            defaultValue={tempMinVal}
            min={tempMinVal}
            max={tempMaxVal}
            disabled={false}
            trackStyle={{ backgroundColor: "black" }}
            handleStyle={{
              backgroundColor: "black",
              border: "none",
            }}
          />
        </div>

        <div className="ml-2 text-left" style={styleSlider}>
          <p className="fc-white mb-0">Putar Arah Motif</p>
          <Slider
            onChange={callbackSpin}
            tooltipVisible={false}
            defaultValue={tempArahVal}
            disabled={false}
            trackStyle={{ backgroundColor: "black" }}
            handleStyle={{ backgroundColor: "black", border: "none" }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-center ">{showSlider || tagSlider()}</div>
  );
};
