import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import { network } from "../../utility/network";
import utils from "../../utility/utils";
import arrowLeft from "../../assets/icons/arrow-left-2.png";
import { Collapse } from "react-collapse";
import ICLeftWhite from "../../assets/icons/arrow-left-white.png";
import { useTranslation } from "react-i18next";
import "./detail_share.css";

export default function DetailShare() {
  const [data, setData] = useState({});
  const [ShareImage, setShareImage] = useState({});
  const [openCollapse, setOpenCollapse] = useState(false);
  const [tempStyle, setTempstyle] = useState({ transform: "rotate(0deg)" });
  const [product, setProduct] = useState({});
  const [choosedFloor, setChoosedFloor] = useState(null);
  const [tempRes, setTempRes] = useState(0.0);

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has("token")) {
      network.get(
        `share`,
        { ref: searchParams.get("token") },
        (res) => {
          setChoosedFloor(res.data.url_layout);
          if (res.detail_share !== null) {
            setData(res.detail_share);
          }
          setShareImage(res.data);
          setProduct(res.product);
        },
        (err, msg) => {
          console.log(err);
          console.log("=============");
          console.log(msg);
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {}
      );
    }
  }, [searchParams]);

  const tagShare = () => {
    return (
      <>
        <div className="card__share d-inline-block">
          <div className="">
            <img
              src={choosedFloor}
              alt="Floor taco"
              className="img-fluid taco__floor__share__product"
            />
            <div className="text-white name__product text-center">
              {product.name}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {data.id ? (
        <>
          {/* Tag Share */}

          <Col className="gutter-row detail__share__component" md={24} xs={24}>
            <div className="card__detail__share">
              <div className="">
                {/*  */}
                <div className="row no-gutters mb-2">
                  {/* <div className="col-5">{tagShare()}</div> */}
                  <div className="col-5 ">
                    <div
                      className=" position-relative w-100 bg__gradient_cal d-flex flex-column align-items-center justify-content-center"
                      style={{
                        height: "150px",
                        background: `linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1)
  ),url(${choosedFloor})`,
                      }}
                    >
                      <div className="text-white text-center font-weight-bold">
                        {data.product_name}{" "}
                        <div className="" style={{ fontWeight: 400 }}>
                          {data.layout_name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="cal__box__right text-left">
                  <div className="d-flex justify-content-between">
                    <h4 className=" fontw-9">Rincian</h4>
                  </div>

                  {/* collapse */}
                  <Collapse isOpened={openCollapse}>
                    {/* tablenew */}
                    <table
                      className="mb-4 custom_p"
                      cellPadding={10}
                      cellSpacing={10}
                      style={{ borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="">{t("Kategori")} </div>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="tab"> :</div>{" "}
                        </td>
                        <td className="pl-2 pb-2">
                          {data != null ? data.kategori : "-"} <br />
                          {data.product_name} - {data.layout_name}
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="">{t("Luas Ruangan")} </div>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="tab"> :</div>{" "}
                        </td>
                        <td className="pl-2 pb-2">
                          {data != null
                            ? data.panjang_ruangan * data.lebar_ruangan
                            : "-"}{" "}
                          m<sup>2</sup>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="">{t("Besar Persegi per Box")} </div>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="tab"> :</div>{" "}
                        </td>
                        <td className="pl-2 pb-2">
                          {data != null ? data.besar_perbox : "-"} box
                        </td>
                      </tr>

                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="">{t("Waste")} </div>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="tab"> :</div>{" "}
                        </td>
                        <td className="pl-2 pb-2">
                          {data != null ? data.waste + "%" : "-"}
                        </td>
                      </tr>
                    </table>
                    {/* endtablenew */}
                  </Collapse>
                  {/* endcollapse */}

                  <div className="mb-3 d-flex justify-content-between">
                    <p className="mb-0">Estimasi jumlah yang dibutuhkan</p>
                    <div
                      className="collapseaa-media d-flex justify-content-end align-items-center"
                      onClick={() => {
                        if (openCollapse) {
                          setOpenCollapse(false);
                          setTempstyle({ transform: "rotate(0deg)" });
                        } else {
                          setTempstyle({ transform: "rotate(180deg)" });
                          setOpenCollapse(true);
                        }
                      }}
                    >
                      <p className=" " style={{ marginBottom: "0px" }}>
                        {openCollapse ? t("Sembunyikan") : t("Tampilkan")}
                      </p>
                      <div className="">
                        <img
                          src={ICLeftWhite}
                          className="img-fluid w-15px ml-md-4 ml-2"
                          alt="taco image"
                          style={tempStyle}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <h2 className="jumlah_yang_dibutuhkan fontw-9">
                      {data != null
                        ? Math.ceil(data.jumlah_kebutuhan) + " box"
                        : "-"}
                    </h2>
                    {utils.fetchLocalStorage("image_kategori_cal") ? (
                      <img
                        src={utils.fetchLocalStorage("image_kategori_cal")}
                        alt="taco empty"
                        className="img-fluid"
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  {data ? (
                    <>
                      <div>
                        <p className="">
                          {data != null ? data.panjang_ruangan : "-"}m x{" "}
                          {data != null ? data.lebar_ruangan : "-"}m ={" "}
                          {data != null
                            ? data.panjang_ruangan * data.lebar_ruangan
                            : "-"}{" "}
                          m<sup>2</sup>
                        </p>
                        <p className="">
                          {data.waste / 100 + 1} x (
                          {data.panjang_ruangan * data.lebar_ruangan} m
                          <sup>2</sup> /{" "}
                          {data != null ? data.besar_perbox : "-"} m<sup>2</sup>
                          ) =
                        </p>

                        <h6 className="">
                          {data.res.toFixed(2)} =
                          <span className="fontw-9">
                            {data != null ? Math.ceil(data.res) : "-"} Box
                          </span>
                        </h6>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
