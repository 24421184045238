import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./category.css";
import "./mobile.css";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";

import IC_download from "../../assets/icons/download.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { network } from "../../utility/network";
import { useDraggable } from "react-use-draggable-scroll";
import Back from "../../components/back/back";
import RoomCategory from "../../components/category/RoomCategory";
import { NewUploadField } from "../../components/shared/newUpload";
import RoomCategoryMobile from "../../components/category/mobile";
import ChangeLanguage from "../../components/change-language/changeLanguage";
import utils from "../../utility/utils";

export default function MobileCategory() {
  const [tempData, setTempData] = useState({});
  const [room, setRoom] = useState([]);
  const navigate = useNavigate();
  const [loading_up, setLoading_up] = useState(false);

  let { id } = useParams();
  const [heightDesc, setHeightDesc] = useState(0);
  const [heightVh, setheightVh] = useState(0);
  const [styleHeight, setStyleHeight] = useState({});
  const refTag = useRef();
  const refVh = useRef();

  useEffect(() => {
    setHeightDesc(refTag.current.clientHeight);
    setheightVh(refVh.current.offsetHeight);
    setStyleHeight({
      height: `${
        refVh.current.offsetHeight - refTag.current.offsetHeight - 40
      }px`,
    });
  }, [room]);

  useEffect(() => {
    network.get(`home/${id}`, {}, (res) => {
      setTempData(res.data[0]);
      setRoom(res.data[0].room);
    });
  }, []);

  return (
    <>
      <div
        ref={refVh}
        className="padding__new_layout  h-100vh d-flex flex-column justify-between"
      >
        <div
          ref={refTag}
          className=""
          style={{ position: "relative", zIndex: 2 }}
        >
          <div className="mb-md-4 mb-5 d-flex justify-content-between align-items-top">
          <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
            <div className="d-flex mobile">

              <div className="pr-2" style={{ zIndex: 31 }}>
                <ChangeLanguage />
              </div>
              <div
                className="d-md-none
              "
              >
                <NewUploadField setLoading_up={setLoading_up} />
              </div>
            </div>
          </div>
          {/* <div className="">
            <div className="">
              <Back />
            </div> 
          </div> */}
          <div className="row">
            <div className="col-12">
              <div
                className=""
                style={{ position: "absolute", top: "calc(-3rem - -1.5rem)" }}
              >
                <Back />
              </div>
              <h1 className="title__category__mobile font-ghotam font-bold">
                {utils.languangeT(tempData.name, tempData.name_en)}
              </h1>
              <p
                className="mt-2 mb-2 font-ghotam-book"
                style={{ fontSize: "14px" }}
              >
                {utils.languangeT(tempData.decription, tempData.decription_en)}
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters " style={styleHeight}>
          {room.map((item, index) => {
            return (
              <RoomCategoryMobile
                key={index + 1}
                type={item.room_categori_id}
                keyIndex={index}
                title={item.name}
                title_en={item.name_en}
                id={item.id}
                imgURL={`${process.env.REACT_APP_STORAGE_URL}${item.image}`}
                imgUrlMobile={`${process.env.REACT_APP_STORAGE_URL}${
                  item.image_mobile === null ? item.image : item.image_mobile
                }`}
                originImage={`${process.env.REACT_APP_STORAGE_URL}${item.image_full}`}
                originImageMobile={`${process.env.REACT_APP_STORAGE_URL}${
                  item.image_full_mobile === null
                    ? item.image_full
                    : item.image_full_mobile
                }`}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
