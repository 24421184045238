import { Card, Col } from "antd";
import { Link } from "react-router-dom";
import utils from "../../utility/utils";

export const RoomItem = ({
  id,
  title,
  imgURL,
  imgUrlMobile,
  type,
  className,
  originImage,
  originImageMobile,
}) => {
  let pathlink = id === 0 ? `/experiment` : `/room/${id}`;
  let classItem = `item__${id} ${className}`;

  if (utils.isNotEmpty(type)) {
    pathlink = `/experiment`;
  }

  if (className === "skeleton_loading") {
    pathlink = "#";
  }

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleClickRoom = () => {
    if (utils.isNotEmpty(type)) {
      utils.saveLocalStoreage("_image", imgURL);
      utils.saveLocalStoreage("_imageMobile", imgUrlMobile);
      utils.saveLocalStoreage("_originImage", originImage);
      utils.saveLocalStoreage("_originImageMobile", originImageMobile);
      utils.saveLocalStoreage("_idRoom", id);
    } else {
      utils.saveLocalStoreage("_title", title);
    }

    utils.saveLocalStoreage("is_upload", false);
  };

  return (
    <Col xs={24} sm={24} md={8} onClick={handleClickRoom}>
      <Link to={pathlink}>
        <Card
          className={utils.classNames(
            "relative border-sm home__menu__item",
            classItem
          )}
          hoverable
        >
          {!imgURL || (
            <img src={originImage} className="image__room" alt="taco empty" />
          )}
          {!title || (
            <div className="absolute menu__title">
              <h6 className="fc-white">{title}</h6>
            </div>
          )}
        </Card>
      </Link>
    </Col>
  );
};
