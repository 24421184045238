import { Slider } from "antd";
import { useEffect, useState } from "react";

export const SliderArah = ({ showSlider, callbackSpin, arahVal }) => {
  const styleSlider =
    window.innerWidth < 800 ? { width: "120px" } : { width: "100%" };

  useEffect(() => {}, [arahVal]);

  const tagSlider = () => {
    return (
      <div className="w-100 ">
        <div className="ml-2 text-left" style={styleSlider}>
          <p className="fc-white mb-0">Putar Arah Motif</p>
          <Slider
            onChange={callbackSpin}
            tooltipVisible={false}
            value={arahVal}
            trackStyle={{ backgroundColor: "black" }}
            handleStyle={{ backgroundColor: "black", border: "none" }}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-center ">{showSlider || tagSlider()}</div>
  );
};
