import { Select } from "antd";
import React, { useEffect } from "react";
import utils from "../../utility/utils";
import { useTranslation } from "react-i18next";
import "../../index.css";
 
export default function ChangeLanguage() {
  const { t, i18n } = useTranslation();

  const handleChange = (value) => {
    utils.saveLocalStoreage("language", value);
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    if (utils.fetchLocalStorage("language")) {
      i18n.changeLanguage(utils.fetchLocalStorage("language"));
    }
  }, []);

  return (
    <>
      <Select
        style={{ fontSize: 12}}
        defaultValue={
          utils.fetchLocalStorage("language")
            ? utils.fetchLocalStorage("language")
            : "en"
        }
        // style={{ width: 120 }}
        onChange={handleChange}
        dropdownClassName="no-border-radius" 
        options={[
          {
            value: "id",
            label: "In",
          },
          {
            value: "en",
            label: "En",
          },
        ]}
      />
    </>
  );
}
