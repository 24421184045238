import "../../assets/css/bootstrap.min.css";
import "./newlayout.css";
import "./experiment.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { Card, Col, Image, Modal, Row, Select, Tooltip } from "antd";
import NavButtonExperiment from "../../components/experiment/nav-button";
import NavButtonExperimentNew from "../../components/experiment/nav-button-new";
import { useSpring } from "react-spring/three";
import React, { useState, useEffect, createRef, useCallback } from "react";
import { Canvas } from "react-three-fiber";
import "./styles.css";
import "./share.css";
import utils from "../../utility/utils";
import { CanvasImage } from "../../components/experiment/canvas-image";
import { network } from "../../utility/network";
import { Link, useSearchParams } from "react-router-dom";
import watermark from "../../assets/images/logo.png";

import { ExperimentActionV2 } from "../../components/experiment/experimen-action-v2";

import DetailShare from "../../components/experiment/detail_share";
import logoBlack from "../../assets/images/Logo_Floor_View_Black.png";

export default function ShareExp() {
  const [floors, setFloors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const [patterns, setPatterns] = useState([]);
  const [patternsAssets, setPatternsAssets] = useState([]);
  const [showed, setShowed] = useState("category");
  const [choosedFloor, setChoosedFloor] = useState(null);

  const [image, setImage] = useState("");
  const [originImage, setOriginImage] = useState("");
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const [styleWidth, setStyleWidth] = useState({});
  const [loadingUp, setLoadingUp] = useState(false);
  const [data, setData] = useState({});
  const [ShareImage, setShareImage] = useState({});
  const [openCollapse, setOpenCollapse] = useState(false);
  const [tempStyle, setTempstyle] = useState({ transform: "rotate(180deg)" });
  const [product, setProduct] = useState({});
  const [colLeft, setColLeft] = useState("");

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [0.9, 0.9, 0.9],
    rotation: [0, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const [{ rotation }, setRotation] = useSpring(() => ({
    // positioning the floor [X, Y, Z]
    // -50, biar kemiringannya pas defaultnya
    rotation: [-50, 0, 0],
    position: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  var constAngle = 0;
  var constZoom = -50;

  const handleChangeSlider = (val) => {
    // handle rotate image
    const angle = val + ((val * 10) / window.innerHeight) * 180;

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [constZoom, 0, angle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constAngle = angle;
  };

  const handleArrangeFloor = (val) => {
    // handle zoom image
    const zoom = val + ((val * 10) / window.innerHeight) * 180; // 550 for ipad

    // for handle when user swipe the screen
    // it doesnt spin anywhere
    const vxyz = [0, 0, 0];

    // update the rotation
    setRotation({
      rotation: [zoom, 0, constAngle],
      immediate: true,
      config: { velocity: vxyz, decay: true },
    });
    constZoom = zoom;
  };

  const [imgWidth, setImageWidth] = useState(null);
  const [imgClient, setImageClient] = useState(null);
  const [imgHeightClient, setImageHeightClient] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);
  const [imageIsReady, setImageIsReady] = useState(false);

  const handleImageLoad = (event) => {
    // real code
    const imWidth = event.target.naturalWidth;
    const imgHeight = event.target.naturalHeight;

    if (imWidth < imgHeight) {
      setImageWidth(window.innerWidth);
      setImageHeight(window.innerHeight - 100);
    } else {
      setImageWidth(imWidth);
      setImageHeightClient(imgHeight);
    }

    setImageClient(event.target.clientWidth);
    setImageHeight(event.target.clientHeight);

    setImageIsReady(true);

    hendleStyleCanvas();
  };

  // choose floor to be set in CANVAS
  const handleChooseFloor = (floor) => {
    let imageFLoor = getBase64FromUrl(floor);
    handleLoading();
    setChoosedFloor(floor);
  };

  const fetchPattern = (id) => {
    network.get(
      `pattern-by-product/${id}`,
      {},
      (res) => {
        setPatterns(res.layouts);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const hendleStyleCanvas = () => {
    if (550 <= window.innerWidth && window.innerWidth < 800) {
      setStyleWidth({ width: `100%`, height: "450px" });
    } else {
      if (window.innerWidth < 550) {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `100%`, height: "250px" });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      } else {
        if (imgWidth >= imgHeight) {
          setStyleWidth({ width: `${imgClient ?? 0}px` });
        } else {
          setStyleWidth({
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
          });
        }
      }
    }
  };

  const styleCanvasFrame =
    550 <= window.innerWidth && window.innerWidth < 800
      ? { width: `100%`, height: "450px" }
      : window.innerWidth < 550
      ? imgWidth >= imgHeight
        ? { width: `100%`, height: "250px" }
        : {
            height: `${imgHeight ?? 0}px`,
            width: `${imgWidth ?? 0}px`,
            // border: "1px solid green",
          }
      : imgWidth >= imgHeight
      ? {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "1px solid yellow",
        }
      : {
          width: `${imgClient ?? 0}px`,
          height: imgHeight,
          // border: "4px solid yellow",
        };

  useEffect(() => {
    setLoading(true);
    network.get(
      `list-kategori`,
      {},
      (res) => {
        setFloors(res.data);
        // console.log(res);
      },
      (err, msg) => {
        let errmsg = utils.erorCode(err);
        utils.messageError(errmsg);
      },
      () => {
        setLoading(false);
      }
    );

    if (searchParams.has("token")) {
      network.get(
        `share`,
        { ref: searchParams.get("token") },
        (res) => {
          setChoosedFloor(res.data.url_layout);
          setImage(res.data.image);
          if (res.detail_share !== null) {
            setData(res.detail_share);
          }
          if (res.detail_share !== null || res.data.is_upload !== null) {
            setColLeft("show");
          } else {
            setColLeft("hidden");
          }
          setShareImage(res.data);
          setProduct(res.product);
        },
        (err, msg) => {
          let errmsg = utils.erorCode(err);
          utils.messageError(errmsg);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [searchParams]);

  // useEffect(() => {
  //   console.log("Data detail share", data, data.id);
  // }, [data]);

  const handleBackCarousel = () => {
    setSelectedPattern(0);
    setPatterns([]);
    setPatternsAssets([]);
    if (showed === "bricks") {
      setShowed("products");
    } else if (showed === "products") {
      setShowed("category");
    }
  };

  const handleLoading = () => {
    let lprogress = setTimeout(() => {
      clearInterval(lprogress);
    }, 2000);
  };

  const tagRight = () => {
    if (colLeft === "show") {
      return (
        <>
          <div className="col-md-4 height_calc__layout layout__right padding__new_layout ">
            <div className="mb-md-4 mb-3 d-md-flex justify-content-between align-items-center d-none">
            <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
            </div>

            <div className="mt-md-5 mb-md-5">
              <DetailShare />
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  // *********** return data
  return (
    <>
      <div className={`${window.innerWidth > 768 ? "overflow-hidden" : null}`}>
        <div
          className={
            "row justify-content-between no-gutters " +
            (window.innerWidth < 991 ? "no-gutters" : "") +
            (colLeft === "hidden" ? " mt-3" : "")
          }
        >
          {/* mobile view */}
          <div className={`col-md-7 text-white d-md-none`}>
            <div
              className="container-fluid position-fixed"
              style={{ zIndex: "20", backgroundColor: "white" }}
            >
              <div className="mb-md-4 mb-3 d-flex justify-content-between align-items-center d-md-none ">
              <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
              </div>
            </div>
            <div className="container-fluid">
              <div className="mb-md-4 mb-3 d-flex justify-content-between align-items-center d-md-none ">
              <Link to="/home">
            <img
              src={logoBlack}
              className="img-fluid logo_nav custom-logo-style"
              alt=""
            />
          </Link>
              </div>
            </div>

            {searchParams.has("token") || <NavButtonExperimentNew />}
            <div className="position-fixed" style={{ zIndex: "10" }}>
              <div
                className={`${window.innerWidth > 768 ? "h-100" : ""}`}
                id="layout__desktop__download"
                style={{ position: "relative" }}
              >
                <div
                  className="canvas__field mx-auto"
                  style={
                    window.innerWidth > 768
                      ? { width: "100%", height: "100%" }
                      : { width: "100%", height: "100%" }
                  }
                >
                  <Canvas
                    pixelRatio={window.devicePixelRatio || 1}
                    camera={{ fov: 75, position: [0, 0, 7] }}
                    gl={{ preserveDrawingBuffer: true }}
                  >
                    <CanvasImage
                      className="rotated_floor"
                      url={
                        choosedFloor === null
                          ? patternsAssets.length === 0
                            ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                            : process.env.REACT_APP_STORAGE_URL +
                              patternsAssets[0].image
                          : choosedFloor
                      }
                      {...props}
                      rotation={rotation}
                    />
                  </Canvas>
                  {/* {image === "" || (
                  <img
                    className="watermark_image d-none"
                    src={watermark}
                    alt="empty watermark"
                  />
                )} */}
                </div>

                <img
                  src={
                    choosedFloor === null && patternsAssets.length === 0
                      ? originImage
                      : image
                  }
                  onLoad={handleImageLoad}
                  alt="taco empty new-layout"
                  className={"img-fluid image__rooom"}
                />
              </div>
            </div>

            <div
              className={`${window.innerWidth > 768 ? "h-100" : ""}`}
              id="layout__desktop__download"
              style={{ position: "relative" }}
            >
              <div
                className="canvas__field mx-auto"
                style={
                  window.innerWidth > 768
                    ? { width: "100%", height: "100%" }
                    : { width: "100%", height: "100%" }
                }
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {/* {image === "" || (
                  <img
                    className="watermark_image d-none"
                    src={watermark}
                    alt="empty watermark"
                  />
                )} */}
              </div>

              <img
                src={
                  choosedFloor === null && patternsAssets.length === 0
                    ? originImage
                    : image
                }
                onLoad={handleImageLoad}
                alt="taco empty new-layout"
                className={"img-fluid image__rooom"}
              />
            </div>
          </div>
          {/* end mobile view */}

          {tagRight()}
          {/* desktop view */}
          <div className={`col-md-8 pl-5 text-white d-none d-md-block`}>
            {searchParams.has("token") || <NavButtonExperimentNew />}
            <div
              className="h-100"
              id="layout__desktop__download"
              style={{ position: "fixed" }}
            >
              <div
                className="canvas__field mx-auto"
                style={
                  window.innerWidth > 768
                    ? { width: "100%", height: "100%" }
                    : styleCanvasFrame
                }
              >
                <Canvas
                  pixelRatio={window.devicePixelRatio || 1}
                  camera={{ fov: 75, position: [0, 0, 7] }}
                  gl={{ preserveDrawingBuffer: true }}
                >
                  <CanvasImage
                    className="rotated_floor"
                    url={
                      choosedFloor === null
                        ? patternsAssets.length === 0
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAGxZgBhnD-ZxjaxZixSb-dVclOiqQWSUdSw&usqp=CAU"
                          : process.env.REACT_APP_STORAGE_URL +
                            patternsAssets[0].image
                        : choosedFloor
                    }
                    {...props}
                    rotation={rotation}
                  />
                </Canvas>
                {/* {image === "" || (
                  <img
                    className="watermark_image"
                    src={watermark}
                    alt="empty watermark"
                  />
                )} */}
              </div>

              <img
                src={
                  choosedFloor === null && patternsAssets.length === 0
                    ? originImage
                    : image
                }
                onLoad={handleImageLoad}
                alt="taco empty new-layout"
                className={"img-fluid image__rooom"}
              />
            </div>
          </div>
          {/* enddesktop view */}
        </div>
      </div>
    </>
  );
}
