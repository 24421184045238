import { animated } from "react-spring/three";
import React, { useEffect } from "react";
import imageTester from "../../assets/images/floor.webp";

export const CanvasTexture = ({
  texture,
  hoverValue,
  opacity,
  onHover,
  ...props
}) => {
  return (
    <>
      <animated.mesh
        onPointerMove={(e) => onHover(true)}
        onPointerOver={(e) => onHover(true)}
        onPointerOut={(e) => onHover(false)}
        {...props}
      >
        {window.innerWidth < 1054 && window.innerWidth > 768 ? (
          <planeBufferGeometry attach="geometry" args={[35, 35]} />
        ) : (
          <planeBufferGeometry attach="geometry" args={[31, 31]} />
        )}
        <meshBasicMaterial attach="material" transparent map={texture} />
      </animated.mesh>
    </>
  );
};
